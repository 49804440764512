/*--------------------------------------------------------------
## buttons
--------------------------------------------------------------*/
a,
button {
	appearance: none;
	outline: none;
}

button,
input[type='button'],
input[type='submit'] {
	font-family: $font-default;
	background: none;
	border: 0;
	cursor: pointer;
	outline: none;
	padding: 0;
}

.btn {
	position: relative;
	font-size: 14px;
	font-weight: 600;
	color: $white;
	text-align: center;
	text-transform: uppercase;
	padding: 14px 34px;
	display: inline-block;
	border-radius: 3px;
	background: $revert-gradient;
	background-size: 300% auto;
	.loaded & {
		transition: $duration-slow $ease;
	}
	@include hover {
		background-position: right center;
	}
	@include media-breakpoint-down(md) {
		font-size: 12px;
		padding: 12px 7px 12px 15px;
		letter-spacing: 1px;
	}
}

.btn--small {
	font-size: 12px;
	padding: 12px 15px;
	letter-spacing: 1px;
}

.btn--white {
	background-color: $white;
	color: $color-primary;
	@include hover {
		background-color: $color-primary;
		color: $white;
	}
}

.btn--transparent {
	background-color: transparent;
	@include hover {
		background: $gradient;
		color: $white;
	}
}

.btn--outline {
	padding: 14px 32px;
	position: relative;
	background: transparent;
	border-radius: 0;
	color: $silver-darkest;
	border-right: 2px solid $orange-dark;
	border-left: 2px solid $red-dark;
	&::after,
	&::before {
		content: '';
		display: block;
		height: 2px;
		position: absolute;
		left: 0;
		right: 0;
		background: $gradient;
	}
	&::before {
		top: 0;
	}
	&::after {
		bottom: 0;
	}
}

// .btn--cta {
// 	padding: 16px 48px 16px 20px;
// 	&::after {
// 		@include font-styles;
// 		content: font-char(ico-chevron-down);
// 		font-size: 12px;
// 		position: absolute;
// 		top: 19px;
// 		right: 18px;
// 		transform: rotate(-90deg);
// 	}
// 	&.btn--outline {
// 		padding: 14px 46px 14px 18px;
// 	}
// 	&.btn--small {
// 		padding: 12px 35px 12px 15px;
// 		&::after {
// 			font-size: 10px;
// 			top: 14px;
// 			right: 12px;
// 		}
// 	}
// 	@include media-breakpoint-down(md) {
// 		padding: 12px 36px 12px 14px;
// 		&::after {
// 			font-size: 10px;
// 			top: 14px;
// 			right: 12px;
// 		}
// 	}
// }
