/*--------------------------------------------------------------
## typography
--------------------------------------------------------------*/

.rte h1, .rte h2, .rte h3, .rte h4, .rte h5, .rte h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	font-family: $font-default;
	font-weight: $regular;
	line-height: 1.3em;
	letter-spacing: 0.3px;
	margin-bottom: 0.5em;
	a {
		color: inherit;
		@include hover {
			text-decoration: underline;
		}
	}
}

.rte h1, .h1 {
	font-size: 28px;
	line-height: 36px;
	font-weight: 700;
	@include media-breakpoint-down(sm) {
		font-size: 22px;
		line-height: 30px;
	}
}

.rte h2, .h2 {
	font-size: 26px;
	line-height: 30px;
	font-family: $font-bebas;
	text-transform: uppercase;
	@include media-breakpoint-down(sm) {
		font-size: 20px;
		line-height: 28px;
	}
}

.rte h3, .h3 {
	font-size: 24px;
	line-height: 28px;
	font-family: $font-bebas;
	text-transform: uppercase;
	letter-spacing: 0.4px;
	@include media-breakpoint-down(sm) {
		font-size: 18px;
		line-height: 26px;
	}
}

.rte h4, .h4 {
	font-size: 22px;
	line-height: 30px;
	font-family: $font-bebas;
	text-transform: uppercase;
	@include media-breakpoint-down(sm) {
		font-size: 16px;
		line-height: 26px;
	}
}

.rte h5, .h5 {
	font-size: 20px;
	line-height: 22px;
	font-family: $font-bebas;
	text-transform: uppercase;
	@include media-breakpoint-down(sm) {
		font-size: 16px;
		line-height: 26px;
	}
}

.rte h6, .h6 {
	font-size: 18px;
	line-height: 34px;
}

.rte p, .text {
	font-size: 18px;
	font-weight: $light;
	line-height: 34px;
	margin-bottom: 30px;
	a {
		@include hover {
			text-decoration: underline;
		}
	}
	@include media-breakpoint-down(sm) {
		font-size: 16px;
		line-height: 26px;
	}
}

.text--small {
	font-size: 16px;
	line-height: 24px;
}

.text--caption {
	font-size: 14px;
	line-height: 24px;
}

.rte {
	h1 {
		margin-bottom: 32px;
	}
	h2 {
		margin-bottom: 20px;
	}
	h3 {
		padding-top: 8px;
		margin-bottom: 18px;
	}
	h4 {
		padding-top: 10px;
		margin-bottom: 16px;
	}
	a:not(.btn):not(.icon-link) {
		color: $aqua-light;
		text-decoration: underline;
		@include hover {
			text-decoration: none;
		}
	}
	.standfirst {
		font-size: 22px;
		color: $gray;
		line-height: 32px;
	}
	img {
		padding-top: 35px;
		margin-bottom: 65px;
	}
	iframe {
		margin-bottom: 60px;
	}
	@include media-breakpoint-only(md) {
		img {
			padding-top: 0;
		}
	}
	@include media-breakpoint-down(sm) {
		h1 {
			margin-bottom: 18px;
		}
		h2 {
			margin-bottom: 15px;
		}
		h3 {
			margin-bottom: 22px;
		}
		h4 {
			margin-bottom: 10px;
		}
		.standfirst {
			font-size: 20px;
			line-height: 28px;
			margin-bottom: 20px;
		}
		img {
			padding-top: 0;
			margin-bottom: 22px;
		}
		iframe {
			margin-bottom: 30px;
		}
	}
}

.blockquote {
	margin-top: 63px;
	margin-bottom: 91px;
	text-align: center;
	padding: 52px 23px 30px;
	position: relative;
	&::after,
	&::before {
		content: '';
		position: absolute;
		display: block;
		background: $gradient-line;
		height: 3px;
		left: 0;
		right: 0;
	}
	&::after {
		bottom: 0;
	}
	&::before {
		top: 0;
	}
	cite {
		display: block;
		font-style: normal;
	}
	.blockquote__text {
		font-size: 24px;
		font-weight: 200;
		line-height: 36px;
		color: $color-primary;
	}
	.blockquote__name {
		text-transform: uppercase;
		font-size: 20px;
		letter-spacing: 0.3px;
		display: block;
		font-family: $font-bebas;
		font-weight: normal;
	}
	.blockquote__job-title {
		font-size: 20px;
		line-height: 30px;
		display: block;
		font-family: $font-default;
	}
	@include media-breakpoint-down(sm) {
		margin-top: 0;
		margin-bottom: 30px;
		padding: 28px 0 24px;
		.blockquote__text {
			font-size: 20px;
			line-height: 28px;
			letter-spacing: 0.38px;
			margin-bottom: 20px;
		}
	}
}

table {
	width: 100%;
	margin-top: 60px;
	margin-bottom: 50px;
	tr {
		border-bottom: 1px solid $gray-light;
		&:first-of-type {
			border-bottom: 0;
		}
	}
	th {
		background-color: $pink;
		text-transform: uppercase;
		font-family: $font-bebas;
		font-weight: $regular;
		letter-spacing: 1px;
		color: $white;
		text-align: left;
		font-size: 22px;
		padding: 16px 18px 16px 0;
		&:first-of-type {
			padding-left: 18px;
		}
	}
	td {
		text-align: left;
		font-size: 16px;
		color: $black;
		font-weight: $regular;
		padding: 21px 18px 21px 0;
		&:first-of-type {
			padding-left: 20px;
		}
	}
	@include media-breakpoint-down(md) {
		width: 760px;
		margin: 0;
	}
}

.table {
	@include media-breakpoint-down(md) {
		position: relative;
		margin-top: 20px;
		margin-bottom: 32px;
		&::after {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			width: 84px;
			height: 100%;
			background-image: linear-gradient(to right, rgba($black, 0) 0%, rgba($black, 0.5) 100%);
			.loaded & {
				transition: opacity $duration $ease;
			}
		}
	}
	@include media-breakpoint-only(md) {
		width: calc(100% + 70px);
	}
	@include media-breakpoint-down(sm) {
		width: calc(100% + 20px);
	}
}

.table--scrolled {
	@include media-breakpoint-down(md) {
		&::after {
			opacity: 0;
		}
	}
}

.table__wrap {
	@include media-breakpoint-down(md) {
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;// sass-lint:disable-line no-vendor-prefixes
	}
}

.icon-link {
	position: relative;
	font-size: 14px;
	font-weight: $medium;
	color: $blacker;
	line-height: 24px;
	padding-left: 23px;
	margin-bottom: 12px;
	display: inline-block;
	&::before {
		@include font-styles;
		font-size: 12px;
		height: 12px;
		overflow: hidden;
		position: absolute;
		top: 6px;
		left: 0;
		display: inline-block;
		color: $pink;
		.loaded & {
			transition: $duration $ease;
		}
	}
	@include hover {
		text-decoration: underline;
	}
}
.cta-link {
	&::before {
		content: font-char(ico-long-arrow);
	}
}
.calendar-link {
	&::before {
		content: font-char(ico-event);
		font-size: 16px;
		height: 16px;
		top: 3px;
	}
}
.pdf-link {
	&::before {
		content: font-char(ico-pdf);
	}
}
.pdf-link__file-size {
	color: $color-secondary;
	margin-left: 5px;
}
.file-link {
	&::before {
		content: font-char(ico-document);
	}
}
.file-link__file-size {
	color: $color-secondary;
}
.download-link {
	&::before {
		content: font-char(ico-long-arrow);
		transform: rotate(90deg);
	}
}
.download-link__file-size {
	color: $gray;
	margin-left: 4px;
}
.play-link {
	&::before {
		content: font-char(ico-play);
		font-size: 10px;
	}
}
.play-link__duration {
	color: $color-secondary;
	margin-left: 4px;
}
.email-link {
	white-space: nowrap;
	&::before {
		content: font-char(ico-mail);
		font-size: 14px;
		height: 14px;
	}
}
.phone-link {
	&::before {
		content: font-char(ico-phone);
		font-size: 14px;
		height: 14px;
	}
}

img {
	max-width: 100%;
	height: auto;
}

iframe {
	border: 0;
	width: 100%;
}
