/*--------------------------------------------------------------
## email-alerts
--------------------------------------------------------------*/

.email-alerts,
.umbraco-forms-page {
	max-width: 560px;
	margin-top: -2px;
	margin-bottom: 85px;
	@include media-breakpoint-only(lg) {
		padding-top: 12px;
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 80px;
	}
}

.email-alerts__submit {
	min-width: 150px;
	margin-right: 16px;
	@include media-breakpoint-down(sm) {
		width: 100%;
		margin: 0 0 15px;
	}
}
span.form__label {
	cursor: default;
	padding-left: 45px;

	a {
		color: #59b7b3;
		text-decoration: underline;

		@include hover {
			text-decoration: none;
		}
	}
}
.form_checkbox-gdpr {
	margin-bottom: 32px;
}
