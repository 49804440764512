/*--------------------------------------------------------------
## people
--------------------------------------------------------------*/

.people {
	display: flex;
	flex-wrap: wrap;
	padding-top: 26px;
	margin: 0 -15px;
	@include media-breakpoint-between(md, lg) {
		margin: 0 -7.5px;
	}
	@include media-breakpoint-only(lg) {
		padding-top: 40px;
	}
	@include media-breakpoint-only(md) {
		padding: 0 50px;
		margin-bottom: 50px;
	}
	@include media-breakpoint-down(sm) {
		padding-top: 0;
		margin: 0 0 30px;
	}
}

.people__group {
	flex: 1 1 33.333%;
	max-width: 33.333%;
	padding: 0 7.5px;
	margin-bottom: 60px;
	@include media-breakpoint-only(lg) {
		margin-bottom: 54px;
	}
	@include media-breakpoint-only(md) {
		flex: 1 1 50%;
		max-width: 50%;
		margin-bottom: 25px;
	}
	@include media-breakpoint-down(sm) {
		flex: 1 1 100%;
		max-width: 100%;
		padding: 0;
		margin-bottom: 35px;
	}
}

.people__image-container {
	position: relative;
	padding-bottom: 55.6%;
	margin-bottom: 28px;
	overflow: hidden;
	@include media-breakpoint-between(md, lg) {
		margin-bottom: 18px;
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 20px;
	}
}

.people__name {
	margin-bottom: 5px;
}

.people__job {
	font-size: 16px;
	font-weight: $light;
	line-height: 24px;
	letter-spacing: 0.44px;
	display: block;
}
