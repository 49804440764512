/*--------------------------------------------------------------
## tabs
--------------------------------------------------------------*/

.tabs {
	position: relative;
	padding-top: 6px;
	margin-bottom: 30px;
	@include media-breakpoint-down(sm) {
		padding-top: 0;
	}
}

.tabs__toggle {
	position: relative;
	min-width: 180px;
	background-color: #fdfdfd;
	padding-left: 15px;
	padding-right: 40px;
	margin-bottom: 20px;
	font-size: 14px;
	font-weight: $light;
	color: $color-primary;
	line-height: 38px;
	height: 40px;
	text-align: left;
	border: 1px solid $gray-lightest;
	display: inline-block;
	.loaded & {
		transition: $duration $ease;
	}
	&::after {
		@include font-styles;
		content: font-char(ico-chevron-down);
		position: absolute;
		right: 16px;
		top: 16px;
		font-size: 10px;
		color: $color-secondary;
		.loaded & {
			transition: $duration $ease;
		}
	}
	@include media-breakpoint-up(md) {
		display: none;
	}
	@include media-breakpoint-down(sm) {
		width: 100%;
	}
}

.tabs__toggle--active {
	border-color: $color-primary;
	&::after {
		transform: rotate(-180deg);
	}
}

.tabs__tab {
	display: none;
}

.tabs__tab--active {
	display: block;
}

.tabs__links {
	display: flex;
	padding-left: 0;
	@include media-breakpoint-down(sm) {
		position: absolute;
		top: 40px;
		right: 0;
		width: 100%;
		background-color: #fdfdfd;
		border: 1px solid $color-primary;
		border-top: 0;
		z-index: 20;
		display: none;
	}
}

.tabs__links-item {
	flex: 1 1 auto;
	padding-right: 15px;
	@include media-breakpoint-down(sm) {
		padding-right: 0;
	}
}

.tabs__link {
	width: 100%;
	height: 100%;
	font-size: 16px;
	font-weight: normal;
	text-align: left;
	padding-bottom: 12px;
	padding-top: 4px;
	border-bottom: 4px solid transparent;
	display: block;
	color: $silver-darkest;
	.loaded & {
		transition: $duration $ease;
	}
	@include hover {
		border-color: $color-secondary;
	}
	@include media-breakpoint-between(md, lg) {
		font-size: 16px;
	}
	@include media-breakpoint-down(sm) {
		width: 100%;
		font-size: 14px;
		color: $color-primary;
		line-height: 38px;
		padding: 0 15px;
		text-align: left;
		letter-spacing: 0;
		border-bottom: 0;
	}
}

.tabs__link--active {
	border-color: $color-primary;
	font-weight: $bold;
	@include media-breakpoint-down(sm) {
		font-weight: $light;
		background-color: $gray-lightest;
	}
}

.tabs__content {
	border-top: 1px solid $gray-light;
	margin-top: -1px;
	padding-top: 30px;
	@include media-breakpoint-down(sm) {
		border-top: 0;
		padding-top: 0;
		margin-top: 0;
	}
}
