/*--------------------------------------------------------------
## disclaimer-2
--------------------------------------------------------------*/

.disclaimer-2 {
	margin-bottom: 122px;
	.rte {
		h2 {
			font-size: 28px;
			line-height: 36px;
			margin-bottom: 30px;
		}
		h3 {
			font-size: 22px;
			line-height: 30px;
			padding-top: 14px;
			margin-bottom: 30px;
		}
		p {
			margin-bottom: 31px;
		}
	}
	@include media-breakpoint-only(lg) {
		padding-top: 16px;
	}
	@include media-breakpoint-only(md) {
		padding: 0 50px;
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 40px;
		.rte {
			h2 {
				font-size: 26px;
				line-height: 30px;
			}
		}
	}
}

.disclaimer-2__btns-container {
	padding-top: 5px;
	.btn {
		margin-right: 10px;
	}
	@include media-breakpoint-down(sm) {
		.btn {
			margin-right: 0;
		}
	}
}
