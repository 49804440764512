/*--------------------------------------------------------------
## news-2
--------------------------------------------------------------*/

.news-2 {
	@include media-breakpoint-only(md) {
		padding-left: 50px;
		padding-right: 50px;
		.row {
			[class*='col-'] {
				&:nth-child(3) {
					display: none;
				}
			}
		}
	}
}

.news-2__article {
	height: calc(100% - 60px);
	box-shadow: 0 0 25px rgba($black, 0.15);
	margin-bottom: 60px;
	@include media-breakpoint-only(md) {
		height: calc(100% - 40px);
		margin-bottom: 40px;
		.mixed-modules & {
			margin-left: 62.5px;
			margin-right: 62.5px;
		}
	}
	@include media-breakpoint-down(sm) {
		height: auto;
		margin-bottom: 30px;
	}
}

.news-2__image {
	width: 100%;
	padding-bottom: 56.325%;
	overflow: hidden;
	@include media-breakpoint-only(md) {
		padding-bottom: 58.5%;
	}
	@include media-breakpoint-down(sm) {
		padding-bottom: 56%;
	}
}

.news-2__container {
	padding: 30px 30px 40px;
	@include media-breakpoint-only(lg) {
		padding: 22px 22px 16px;
	}
	@include media-breakpoint-only(md) {
		padding-bottom: 50px;
	}
	@include media-breakpoint-down(sm) {
		padding: 20px 20px 15px;
	}
}

.news-2__tag {
	font-weight: $bold;
	color: $color-primary;
	line-height: 22px;
	text-transform: uppercase;
	margin-right: 12px;
}

.news-2__date {
	line-height: 22px;
	text-transform: uppercase;
	@include media-breakpoint-only(lg) {
		padding-top: 4px;
		display: block;
	}
}

.news-2__title {
	padding-top: 15px;
	font-weight: $light;
	@include media-breakpoint-only(lg) {
		padding-top: 10px;
	}
	@include media-breakpoint-down(sm) {
		padding-top: 10px;
	}
}

.news-2__link {
	color: #1b262b;
	text-decoration: none;
}
