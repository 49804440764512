/*--------------------------------------------------------------
## event-2
--------------------------------------------------------------*/

.event-2 {
	@include media-breakpoint-only(md) {
		padding: 0 50px;
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 20px;
	}
}

.event-2__item {
	text-align: center;
	margin-bottom: 60px;
	height: calc(100% - 60px);
	@include media-breakpoint-only(md) {
		height: auto;
		margin-bottom: 40px;
		.mixed-modules & {
			margin-left: 62.5px;
			margin-right: 62.5px;
		}
	}
	@include media-breakpoint-down(sm) {
		height: auto;
		margin-bottom: 30px;
	}
}

.event-2__main-title {
	margin-bottom: 40px;
	@include media-breakpoint-down(sm) {
		font-size: 20px;
		line-height: 28px;
		margin-bottom: 20px;
	}
}

.event-2__container {
	position: relative;
	margin-top: -66px;
	width: 100%;
	height: calc(100% - 64px);
	padding: 94px 48px 55px;
	border-radius: 5px;
	background-color: $white;
	box-shadow: 0 0 15px 0 rgba($black, 0.12);
	@include media-breakpoint-only(lg) {
		padding: 94px 18px 58px;
	}
	@include media-breakpoint-only(md) {
		height: auto;
		padding: 92px 65px 15px;
	}
	@include media-breakpoint-down(sm) {
		height: auto;
		padding: 92px 15px 18px;
	}
}

.event-2__date {
	position: relative;
	width: 130px;
	height: 130px;
	padding-top: 18px;
	margin: 0 auto;
	background-color: $color-primary;
	border-radius: 8px;
	z-index: 10;
}

.event-2__date-day {
	font-size: 60px;
	font-weight: $bold;
	color: $white;
	line-height: 66px;
	letter-spacing: -0.67px;
	margin-bottom: 4px;
	display: block;
}

.event-2__date-month {
	font-size: 14px;
	color: $white;
	line-height: 22px;
	text-transform: uppercase;
	display: block;
}

.event-2__title {
	font-weight: $bold;
	line-height: 26px;
	letter-spacing: 0.49px;
	margin-bottom: 10px;
	@include media-breakpoint-only(lg) {
		margin-bottom: 15px;
	}
	@include media-breakpoint-only(md) {
		margin-bottom: 10px;
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 15px;
	}
}

.event-2__text {
	margin-bottom: 32px;
	@include media-breakpoint-only(lg) {
		margin-bottom: 20px;
	}
	@include media-breakpoint-only(md) {
		margin-bottom: 22px;
	}
	@include media-breakpoint-down(sm) {
		padding: 0 4px;
		margin-bottom: 20px;
	}
}

.event-2__links {
	width: 100%;
	position: absolute;
	bottom: 18px;
	left: 0;
	padding: 0 10px;
	.icon-link {
		color: $black;
	}
	.cta-link {
		margin-right: 18px;
	}
	@include media-breakpoint-down(md) {
		position: relative;
		bottom: auto;
		left: auto;
		padding: 0;
	}
	@include media-breakpoint-only(md) {
		.cta-link {
			margin-right: 28px;
		}
	}
	@include media-breakpoint-down(sm) {
		.icon-link {
			margin-left: 15px;
			margin-right: 15px;
		}
	}
}

.event-2__item--narrow {
	@include media-breakpoint-up(xl) {
		.event-2__container {
			padding: 80px 18px 55px;
		}
		.event-2__title {
			margin-bottom: 10px;
		}
		.event-2__text {
			margin-bottom: 18px;
		}
	}
	@include media-breakpoint-only(lg) {
		.event-2__container {
			padding: 82px 15px 95px;
		}
		.event-2__title {
			margin-bottom: 8px;
		}
		.event-2__text {
			margin-bottom: 8px;
		}
		.event-2__links {
			.cta-link {
				margin-right: 12px;
			}
		}
	}
}
