/*--------------------------------------------------------------
## news-1
--------------------------------------------------------------*/

.news-1 {
	@include media-breakpoint-only(md) {
		padding-left: 50px;
		padding-right: 50px;
		.row {
			[class*='col-'] {
				&:nth-child(3) {
					display: none;
				}
			}
		}
	}
}

.news-1__article {
	height: calc(100% - 60px);
	box-shadow: 0 0 25px rgba($black, 0.15);
	padding: 30px 30px 40px;
	margin-bottom: 60px;
	@include media-breakpoint-only(lg) {
		padding: 22px 22px 16px;
	}
	@include media-breakpoint-only(md) {
		height: calc(100% - 40px);
		padding-bottom: 50px;
		margin-bottom: 40px;
		.mixed-modules & {
			margin-left: 62.5px;
			margin-right: 62.5px;
		}
	}
	@include media-breakpoint-down(sm) {
		height: auto;
		padding: 20px 20px 15px;
		margin-bottom: 30px;
	}
}

.news-1__tag {
	font-weight: $bold;
	color: $color-primary;
	line-height: 22px;
	text-transform: uppercase;
	margin-right: 12px;
}

.news-1__date {
	line-height: 22px;
	text-transform: uppercase;
	@include media-breakpoint-only(lg) {
		padding-top: 4px;
		display: block;
	}
}

.news-1__title {
	padding-top: 15px;
	font-weight: $light;
	@include media-breakpoint-only(lg) {
		padding-top: 10px;
	}
	@include media-breakpoint-down(sm) {
		padding-top: 10px;
	}
}
