/*--------------------------------------------------------------
## malihu custom scrollbar
--------------------------------------------------------------*/

.mCustomScrollbar {
	-ms-touch-action: pinch-zoom;
	touch-action: pinch-zoom; /* direct pointer events to js */
}

.mCustomScrollbar {
	&.mCS_no_scrollbar,
	&.mCS_touch_action {
		-ms-touch-action: auto;
		touch-action: auto;
	}
}

.mCustomScrollBox {
	position: relative;
	overflow: hidden;
	height: 100%;
	max-width: 100%;
	outline: none;
	direction: ltr;
}

.mCSB_container {
	overflow: hidden;
	width: auto;
	height: auto;
}

.mCSB_inside > .mCSB_container {
	margin-right: 30px;
}

.mCSB_scrollTools {
	position: absolute;
	width: 16px;
	height: auto;
	left: auto;
	top: 0;
	right: 0;
	bottom: 0;
	.mCSB_draggerContainer {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		height: auto;
	}
	.mCSB_draggerRail {
		width: 2px;
		height: 100%;
		margin: 0 auto;
		border-radius: 16px;
	}
	.mCSB_dragger {
		cursor: pointer;
		width: 100%;
		height: 50px;
		z-index: 1;
	}
	.mCSB_dragger_bar {
		position: relative;
		width: 4px;
		height: 100%;
		margin: 0 auto;
		border-radius: 16px;
		text-align: center;
		background-color: rgba($white, 0.2);
	}
	.mCSB_buttonUp,
	.mCSB_buttonDown {
		display: block;
		position: absolute;
		height: 20px;
		width: 100%;
		overflow: hidden;
		margin: 0 auto;
		cursor: pointer;
	}
	.mCSB_buttonDown {
		bottom: 0;
	}
}

.mCSB_outside + .mCSB_scrollTools {
	right: 0;
	margin: 12px 0;
}

.mCSB_scrollTools a + .mCSB_draggerContainer {
	margin: 20px 0;
}

.mCSB_scrollTools,
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight {
	.loaded & {
		transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
	}
}

.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
	background-color: rgba($white, 0.5);
}
