/*--------------------------------------------------------------
## video-library
--------------------------------------------------------------*/

.video-library {
	display: flex;
	flex-wrap: wrap;
	padding-top: 28px;
	padding-right: 10px;
	margin: 0 -15px 100px;
	@include media-breakpoint-down(lg) {
		margin-left: -7.5px;
		margin-right: -7.5px;
		padding-right: 0;
	}
	@include media-breakpoint-only(lg) {
		padding-top: 40px;
	}
	@include media-breakpoint-only(md) {
		padding: 0 50px;
		margin-bottom: 50px;
	}
	@include media-breakpoint-down(sm) {
		padding-top: 0;
		margin: 0 0 30px;
	}
}

.video-library__item {
	flex: 1 1 33.333%;
	max-width: 33.333%;
	padding: 0 7.5px;
	margin-bottom: 18px;
	@include media-breakpoint-only(md) {
		flex: 1 1 50%;
		max-width: 50%;
	}
	@include media-breakpoint-down(sm) {
		flex: 1 1 100%;
		max-width: 100%;
		padding: 0;
		margin-bottom: 25px;
	}
}

.video-library__thumbnail {
	position: relative;
	overflow: hidden;
	padding-bottom: 68.15%;
	margin-bottom: 20px;
	@include media-breakpoint-only(lg) {
		margin-bottom: 10px;
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 14px;
	}
}

.video-library__link {
	padding-left: 22px;
}

.video-library__play-button {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10;
	@include hover {
		.video-library__play-icon {
			transform: translate(-50%, -50%) scale(1.2);
		}
	}
}

.video-library__play-icon {
	width: 35px;
	height: 35px;
	border-radius: 50%;
	border: 4px solid $white;
	@include align(absolute, xy);
	.loaded & {
		transition: $duration $ease;
	}
	.icon {
		font-size: 12px;
		color: $white;
		position: absolute;
		top: 7px;
		left: 8px;
	}
}

.video-library__video-container {
	position: relative;
	padding-bottom: 56.25%;
}

.video-library__popup-iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.video-library__popup {
	padding-top: 50px;
	padding-bottom: 50px;
	.popup__close-btn {
		top: 14px;
		right: 10px;
	}
	@include media-breakpoint-only(md) {
		padding: 70px 20px 50px;
		.popup__close-btn {
			top: 24px;
			right: 14px;
		}
	}
	@include media-breakpoint-down(sm) {
		padding-top: 56px;
		padding-bottom: 36px;
	}
}
