//---------- people-2
//------------------------------------------------------------------------------

.people-2__group {
	position: relative;
	flex: 1 1 33.333%;
	max-width: 33.333%;
	padding: 0 7.5px;
	margin-bottom: 60px;
	@include hover {
		.people-2__read-more {
			transform: scale(1.25);
		}
	}
	@include media-breakpoint-only(md) {
		flex: 1 1 50%;
		max-width: 50%;
	}
	@include media-breakpoint-down(sm) {
		flex: 1 1 100%;
		max-width: 100%;
		padding: 0;
		margin-bottom: 35px;
	}
}

.people-2__image-container {
	position: relative;
	padding-bottom: 100%;
	overflow: hidden;
	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-image: linear-gradient(to bottom, rgba($black, 0) 0%, rgba($black, 0.45) 80%);
	}
}

.people-2__info {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 0 40px 16px 28px;
	z-index: 10;
	@include media-breakpoint-down(sm) {
		padding-left: 20px;
	}
}

.people-2__name {
	color: $white;
	margin-bottom: 2px;
	display: block;
	font-size: 18px;
	line-height: 30px;
	font-family: $font-default;
	font-weight: 700;
	text-transform: capitalize;
}

.people-2__job {
	font-size: 16px;
	color: $white;
	line-height: 28px;
	display: block;
}

.people-2__read-more {
	font-size: 30px;
	color: $white;
	position: absolute;
	right: 28px;
	bottom: 28px;
	.loaded & {
		transition: $duration-fast $ease;
	}
}

.people-2__details {
	display: none;
}

.people-2__details-image {
	position: relative;
	margin-top: 6px;
	flex: 0 0 270px;
	@include media-breakpoint-only(md) {
		margin-top: 0;
	}
	@include media-breakpoint-down(sm) {
		width: 100%;
		margin-top: 0;
		margin-bottom: 20px;
	}
}

.people-2__details-info {
	padding-left: 50px;
	flex: 1 1 auto;
	@include media-breakpoint-only(md) {
		padding-left: 30px;
	}
	@include media-breakpoint-down(sm) {
		padding-left: 0;
	}
}

.people-2__details-name {
	font-size: 26px;
	line-height: 30px;
	text-transform: uppercase;
	letter-spacing: 0.4px;
	margin-bottom: 8px;
	font-weight: normal;
	@include media-breakpoint-down(sm) {
		margin-bottom: 12px;
	}
}

.people-2__details-headlines {
	font-size: 18px;
	letter-spacing: normal;
	line-height: 34px;
	margin-bottom: 0;
	margin-top: 30px;
	text-transform: none;
	font-family: $font-default;
	font-weight: 700;
}

.people-2__details-job {
	font-size: 20px;
	line-height: 30px;
	letter-spacing: normal;
	color: $silver-darkest;
	margin-bottom: 22px;
	display: block;
	font-family: $font-default;
	@include media-breakpoint-down(sm) {
		margin-bottom: 18px;
	}
}

.people-2__details-desc {
	font-size: 18px;
	line-height: 34px;
	font-weight: 300;
	letter-spacing: normal;
	color: $silver-darkest;
	display: block;
	@include media-breakpoint-down(sm) {
		margin-bottom: 18px;
	}
}

.people-2__details-bio {
	margin-top: 22px;
	.rte {
		p {
			line-height: 36px;
		}
	}
	@include media-breakpoint-down(sm) {
		.rte {
			p {
				font-size: 18px;
				&:last-of-type {
					margin-bottom: 0;
				}
			}
		}
	}
}

.people-popup {
	@include media-breakpoint-only(lg) {
		padding: 85px 48px 70px;
	}
	@include media-breakpoint-down(md) {
		padding-bottom: 70px;
	}
}
