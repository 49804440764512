@font-face {
	font-family: 'svgicons';
	src: url('../assets/fonts/svgicons.woff2') format('woff2'),
		url('../assets/fonts/svgicons.woff') format('woff'),
		url('../assets/fonts/svgicons.ttf') format('truetype');
}

@mixin font-styles {
	font-family: 'svgicons';
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	// speak: none; // only necessary if not using the private unicode range (firstGlyph option)
	text-decoration: none;
	text-transform: none;
	display: block;
	line-height: 1;
}

%font {
	@include font-styles;
}

@function font-char($filename) {
	$char: "";

	@if $filename == ico-arrow {
		$char: '\E001';
	}
	@if $filename == ico-chevron-down {
		$char: '\E002';
	}
	@if $filename == ico-close {
		$char: '\E003';
	}
	@if $filename == ico-document {
		$char: '\E004';
	}
	@if $filename == ico-download {
		$char: '\E005';
	}
	@if $filename == ico-event {
		$char: '\E006';
	}
	@if $filename == ico-facebook {
		$char: '\E007';
	}
	@if $filename == ico-google-plus {
		$char: '\E008';
	}
	@if $filename == ico-instagram {
		$char: '\E009';
	}
	@if $filename == ico-linkedin {
		$char: '\E00A';
	}
	@if $filename == ico-long-arrow {
		$char: '\E00B';
	}
	@if $filename == ico-mail {
		$char: '\E00C';
	}
	@if $filename == ico-pdf {
		$char: '\E00D';
	}
	@if $filename == ico-phone {
		$char: '\E00E';
	}
	@if $filename == ico-pin {
		$char: '\E00F';
	}
	@if $filename == ico-play {
		$char: '\E010';
	}
	@if $filename == ico-search {
		$char: '\E011';
	}
	@if $filename == ico-share {
		$char: '\E012';
	}
	@if $filename == ico-twitter {
		$char: '\E013';
	}
	@if $filename == ico-up-right {
		$char: '\E014';
	}
	@if $filename == ico-youtube {
		$char: '\E015';
	}

	@return $char;
}

@mixin font($filename, $insert: before, $extend: true) {
	&:#{$insert} {
		@if $extend {
			@extend %font;
		} @else {
			@include font-styles;
		}
		content: font-char($filename);
	}
}

[class*='font-ico'] {
	display: inline-block;
}

.font-ico-arrow {
	@include font(ico-arrow);
}
.font-ico-chevron-down {
	@include font(ico-chevron-down);
}
.font-ico-close {
	@include font(ico-close);
}
.font-ico-document {
	@include font(ico-document);
}
.font-ico-download {
	@include font(ico-download);
}
.font-ico-event {
	@include font(ico-event);
}
.font-ico-facebook {
	@include font(ico-facebook);
}
.font-ico-google-plus {
	@include font(ico-google-plus);
}
.font-ico-instagram {
	@include font(ico-instagram);
}
.font-ico-linkedin {
	@include font(ico-linkedin);
}
.font-ico-long-arrow {
	@include font(ico-long-arrow);
}
.font-ico-mail {
	@include font(ico-mail);
}
.font-ico-pdf {
	@include font(ico-pdf);
}
.font-ico-phone {
	@include font(ico-phone);
}
.font-ico-pin {
	@include font(ico-pin);
}
.font-ico-play {
	@include font(ico-play);
}
.font-ico-search {
	@include font(ico-search);
}
.font-ico-share {
	@include font(ico-share);
}
.font-ico-twitter {
	@include font(ico-twitter);
}
.font-ico-up-right {
	@include font(ico-up-right);
}
.font-ico-youtube {
	@include font(ico-youtube);
}
