/*--------------------------------------------------------------
## page-header
--------------------------------------------------------------*/

.page-header {
	position: relative;
	height: 400px;
	background-color: $color-primary;
	margin-bottom: 55px;
	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba($black, 0.45);
		background-image: linear-gradient(0deg, rgba($black, 0.5) 0%, rgba($black, 0) 100%);
	}
	.wrap {
		height: 100%;
	}
	@include media-breakpoint-between(md, lg) {
		height: 300px;
		margin-bottom: 40px;
	}
	@include media-breakpoint-only(md) {
		height: 300px;
	}
	@include media-breakpoint-down(sm) {
		height: 200px;
		margin-bottom: 30px;
		.wrap {
			padding: 0 16px;
		}
	}
}

.page-header__container {
	position: relative;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	padding: 18px 0;
	z-index: 10;
	@include media-breakpoint-only(md) {
		padding-left: 45px;
		padding-right: 45px;
	}
	@include media-breakpoint-down(sm) {
		padding: 10px 0;
	}
}

.page-header__title {
	font-size: 60px;
	color: $white;
	line-height: 70px;
	font-weight: 600;
	margin-bottom: 20px;
	@include media-breakpoint-only(lg) {
		margin-bottom: 22px;
	}
	@include media-breakpoint-only(md) {
		font-size: 44px;
		line-height: 52px;
		letter-spacing: -0.67px;
		margin-bottom: 25px;
	}
	@include media-breakpoint-down(sm) {
		font-size: 28px;
		line-height: 40px;
		letter-spacing: -0.44px;
		margin-bottom: 16px;
	}
}

.breadcrumbs {
	@include media-breakpoint-down(sm) {
		display: none;
	}
}

.breadcrumbs__list {
	font-size: 0;
}

.breadcrumbs__item {
	position: relative;
	margin-right: 26px;
	margin-bottom: 10px;
	display: inline-block;
	&::after {
		content: '';
		position: absolute;
		top: 5px;
		right: -14px;
		transform: rotate(-45deg);
		width: 5px;
		height: 5px;
		border-bottom: 1px solid $white;
		border-right: 1px solid $white;
	}
	&:last-of-type {
		&::after {
			display: none;
		}
	}
	&:not(:last-of-type) {
		.breadcrumbs__link {
			@include hover {
				text-decoration: underline;
			}
		}
	}
}

.breadcrumbs__link {
	font-size: 14px;
	font-weight: $medium;
	color: $white;
	line-height: 16px;
	letter-spacing: 1.5px;
	text-transform: uppercase;
}

.page-info {
	margin-top: -10px;
	.breadcrumbs__link {
		font-weight: $medium;
		color: $color-primary;
	}
	.breadcrumbs__item {
		&::after {
			border-color: $color-primary;
		}
	}
	.page-info__title {
		font-size: 70px;
		color: $color-primary;
		line-height: 80px;
		letter-spacing: -0.78px;
		padding-top: 22px;
		margin-bottom: 4px;
		margin-left: -5px;
	}
	@include media-breakpoint-between(md, lg) {
		margin-top: 0;
		.page-info__title {
			padding-top: 0;
		}
	}
	@include media-breakpoint-only(md) {
		padding: 0 50px;
		margin-bottom: 30px;
	}
	@include media-breakpoint-down(sm) {
		.page-info__title {
			font-size: 28px;
			line-height: 40px;
			letter-spacing: -0.44px;
			margin-bottom: 16px;
			padding-top: 0;
			margin-left: 0;
		}
	}
}
