/*--------------------------------------------------------------
## annual-report
--------------------------------------------------------------*/

.annual-report {
	position: relative;
	height: calc(100% - 72px);
	padding-bottom: 63.25%;
	margin-bottom: 72px;
	overflow: hidden;
	.icon-link {
		float: left;
		clear: both;
		margin-bottom: 17px;
		&::before {
			margin-right: 15px;
		}
	}
	@include media-breakpoint-only(lg) {
		padding-bottom: 56.25%;
		margin-bottom: 20px;
	}
	@include media-breakpoint-only(md) {
		padding-bottom: 60.5%;
		margin: 0 50px 20px;
	}
	@include media-breakpoint-down(sm) {
		padding-bottom: 0;
		height: auto;
		margin-bottom: 20px;
	}
}

.annual-report__content {
	width: 100%;
	padding: 40px 50% 47px 39px;
	position: absolute;
	top: 0;
	left: 0;
	@include media-breakpoint-only(lg) {
		padding: 42px 40px;
	}
	@include media-breakpoint-only(md) {
		padding: 42px 32px;
	}
	@include media-breakpoint-down(sm) {
		padding: 25px 20px;
		position: relative;
	}
}

.annual-report__content-img {
	position: absolute;
	right: 41px;
	top: 40px;
	max-width: 249px;
	@include media-breakpoint-down(sm) {
		position: relative;
		right: auto;
		top: auto;
	}
}

.annual-report__title {
	margin-bottom: 18px;
	font-size: 30px;
	letter-spacing: 0.45px;
	font-weight: normal;
	@include media-breakpoint-down(sm) {
		font-size: 22px;
		line-height: 30px;
	}
}
