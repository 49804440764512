/*--------------------------------------------------------------
## disclaimer
--------------------------------------------------------------*/

.disclaimer {
	margin-bottom: 90px;
	.rte {
		h2 {
			font-size: 25px;
			margin-bottom: 22px;
		}
		p {
			font-size: 16px;
			line-height: 25px;
			color: $color-primary;
		}
	}
	@include media-breakpoint-only(lg) {
		padding-top: 20px;
	}
	@include media-breakpoint-only(md) {
		margin: 0 -12px 90px;
		.rte {
			max-width: 580px;
		}
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 40px;
		.rte {
			h2 {
				font-size: 26px;
				line-height: 30px;
			}
			p {
				margin-bottom: 22px;
			}
		}
	}
}

.disclaimer__select-container {
	position: relative;
	max-width: 446px;
	margin-bottom: 19px;
	@include media-breakpoint-down(lg) {
		max-width: 396px;
	}
}

.disclaimer__form-submit {
	max-width: 215px;
	width: 100%;
	padding: 14px 20px;
	text-align: center;
	letter-spacing: 1.3px;
	@include media-breakpoint-down(sm) {
		max-width: 100%;
	}
}

.disclaimer__btn {
	height: 44px;
	min-width: 215px;
	letter-spacing: 1.15px;
	padding: 14px 34px;
	@include media-breakpoint-down(sm) {
		font-size: 14px;
		width: 100%;
		margin-bottom: 20px;
	}
}
