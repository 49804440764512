/*--------------------------------------------------------------
## cookies
--------------------------------------------------------------*/

.cookies {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 12px 0 10px;
	text-align: center;
	background-color: $aqua;
	overflow: hidden;
	z-index: 50;
	@include media-breakpoint-only(lg) {
		padding-bottom: 20px;
	}
	@include media-breakpoint-only(md) {
		padding-bottom: 16px;
	}
	@include media-breakpoint-down(sm) {
		padding-bottom: 22px;
		.wrap {
			padding-left: 13px;
			padding-right: 13px;
		}
	}
}

.cookies__text {
	font-size: 16px;
	font-weight: $light;
	color: $white;
	line-height: 28px;
	vertical-align: middle;
	display: inline-block;
	@include media-breakpoint-down(lg) {
		display: block;
	}
}

.cookies__link {
	font-weight: $bold;
	color: $white;
	@include hover {
		text-decoration: underline;
	}
	@include media-breakpoint-only(lg) {
		display: block;
	}
	@include media-breakpoint-down(sm) {
		display: block;
	}
}

.cookies__accept {
	position: relative;
	width: 18px;
	height: 18px;
	background-color: $white;
	border-radius: 50%;
	margin-left: 14px;
	vertical-align: middle;
	display: inline-block;
	.loaded & {
		transition: $duration $ease;
	}
	&::before,
	&::after {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		width: 11px;
		height: 2px;
		background-color: $aqua;
		display: block;
	}
	&::before {
		transform: translate(-50%, -50%) rotate(45deg);
	}
	&::after {
		transform: translate(-50%, -50%) rotate(-45deg);
	}
	@include hover {
		transform: scale(1.2);
	}
	@include media-breakpoint-only(lg) {
		margin-top: 12px;
		margin-left: 0;
	}
	@include media-breakpoint-down(md) {
		width: 27px;
		height: 27px;
		margin-top: 12px;
		margin-left: 0;
		&::before,
		&::after {
			width: 17px;
			height: 3px;
		}
	}
	@include media-breakpoint-down(sm) {
		margin-top: 22px;
	}
}
