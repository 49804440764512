/*--------------------------------------------------------------
## accordion
--------------------------------------------------------------*/

.accordion {
	border-top: 2px solid $gray-light;
	margin-bottom: 88px;
	@include media-breakpoint-down(sm) {
		margin-bottom: 28px;
	}
}

.accordion__item {
	border-bottom: 2px solid $gray-light;
}

.accordion__btn {
	width: 100%;
	padding: 20px 32px 20px 12px;
	font-size: 22px;
	letter-spacing: 0.3px;
	text-align: left;
	font-weight: normal;
	position: relative;
	display: block;
	color: $silver-darkest;
	text-transform: uppercase;
	font-family: $font-bebas;
	line-height: 28px;
	.loaded & {
		transition: $duration $ease;
	}
	&::after {
		content: '';
		position: absolute;
		display: block;
		color: $aqua-light;
		border-bottom: 2px solid $aqua-light;
		border-right: 2px solid $aqua-light;
		width: 9px;
		height: 9px;
		right: 30px;
		top: 50%;
		pointer-events: none;
		transform: translateY(-50%) rotate(45deg);
		.loaded & {
			transition: $duration $ease;
		}
	}
	@include hover {
		background-color: $gray-lightest;
	}
	@include media-breakpoint-down(sm) {
		font-size: 18px;
		line-height: 26px;
		padding: 14px 20px 16px 0;
		&::after {
			right: 4px;
		}
	}
}

.accordion__btn--active {
	&::after {
		transform: rotate(225deg);
	}
}

.accordion__content {
	padding: 10px 100px 10px 12px;
	display: none;
	p,
	.text {
		font-size: 16px;
		line-height: 24px;
	}
	@include media-breakpoint-down(sm) {
		padding: 0;
	}
}
