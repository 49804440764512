/*--------------------------------------------------------------
## sitemap
--------------------------------------------------------------*/

.sitemap {
	display: flex;
	flex-wrap: wrap;
	padding-right: 9%;
	margin-bottom: 72px;
	@include media-breakpoint-only(lg) {
		padding-right: 7%;
	}
	@include media-breakpoint-only(md) {
		padding: 0 50px;
		margin-bottom: 0;
	}
	@include media-breakpoint-down(sm) {
		padding-right: 0;
		margin-bottom: 0;
	}
}

.sitemap__group {
	padding-right: 20px;
	margin-bottom: 35px;
	flex: 1 1 33.333%;
	max-width: 33.333%;
	@include media-breakpoint-between(md, lg) {
		margin-bottom: 45px;
	}
	@include media-breakpoint-down(sm) {
		padding-right: 0;
		margin-bottom: 25px;
		flex: 1 1 100%;
		min-width: 100%;
	}
}

.sitemap__title {
	margin-bottom: 15px;
	text-transform: uppercase;
}

.sitemap__item {
	margin-bottom: 14px;
	&::before {
		@include font-styles;
		content: font-char(ico-long-arrow);
		font-size: 12px;
		color: $pink;
		margin-right: 5px;
		position: relative;
		top: 1px;
		display: inline-block;
	}
}

.sitemap__link {
	font-size: 14px;
	font-weight: $bold;
	color: $black;
	line-height: 24px;
	@include hover {
		text-decoration: underline;
	}
}

.sitemap__secondary-list {
	padding-top: 14px;
	padding-left: 26px;
}

.sitemap__secondary-item {
	margin-bottom: 14px;
}

.sitemap__secondary-link {
	font-size: 14px;
	font-weight: $medium;
	color: $color-primary;
	line-height: 24px;
	@include hover {
		text-decoration: underline;
	}
}
