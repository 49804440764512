/*--------------------------------------------------------------
## error
--------------------------------------------------------------*/

.error {
	margin-bottom: 128px;
	.rte {
		a:not(.btn):not(.icon-link) {
			color: $aqua;
		}
		h3 {
			font-size: 26px;
			margin-bottom: 18px;
			text-transform: uppercase;
		}
		ul {
			margin-left: 8px;
			margin-bottom: 34px;
			li {
				font-size: 16px;
				line-height: 25px;
				margin-bottom: 5px;
				&::before {
					top: 10px;
					background-color: $silver-darkest;
				}
			}
		}
	}
	@include media-breakpoint-only(lg) {
		padding-top: 10px;
		.rte {
			ul {
				margin-bottom: 20px;
			}
		}
	}
	@include media-breakpoint-only(md) {
		margin: 0 -12px 96px;
		.rte {
			max-width: 540px;
			ul {
				margin-bottom: 20px;
			}
		}
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 50px;
		.rte {
			h3 {
				font-size: 18px;
				margin-bottom: 15px;
			}
			ul {
				margin-bottom: 16px;
				li {
					font-size: 14px;
					line-height: 22px;
				}
			}
		}
	}
}
