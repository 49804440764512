/*--------------------------------------------------------------
## featured-news
--------------------------------------------------------------*/

.featured-news {
	position: relative;
	height: calc(100% - 60px);
	padding-bottom: 42px;
	margin-bottom: 60px;
	.icon-link {
		position: absolute;
		bottom: 0;
		left: 0;
	}
	@include media-breakpoint-only(lg) {
		height: calc(100% - 30px);
		margin-bottom: 30px;
	}
	@include media-breakpoint-only(md) {
		height: auto;
		padding-left: 50px;
		padding-right: 50px;
		margin-bottom: 40px;
		.icon-link {
			left: 50px;
		}
	}
	@include media-breakpoint-down(sm) {
		height: auto;
		padding-bottom: 30px;
		margin-bottom: 26px;
	}
}

.featured-news__title-wrap {
	display: flex;
	align-items: flex-end;
	margin-bottom: 20px;
	@include media-breakpoint-down(sm) {
		margin-bottom: 15px;
	}
}

.featured-news__title {
	width: 100%;
	padding-bottom: 18px;
	margin-bottom: 0;
	position: relative;
	font-size: 30px;
	line-height: 30px;
	letter-spacing: -0.7px;
	&::after {
		content: '';
		display: block;
		background: $gradient-line;
		position: absolute;
		height: 2px;
		left: 0;
		right: 0;
		bottom: 0;
	}
	@include media-breakpoint-only(lg) {
		padding-bottom: 8px;
	}
	@include media-breakpoint-down(sm) {
		font-size: 20px;
		line-height: 28px;
	}
}

.featured-news__item {
	margin-bottom: 45px;
	@include media-breakpoint-only(lg) {
		margin-bottom: 16px;
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 20px;
	}
}

.featured-news__date {
	font-size: 14px;
	font-weight: $light;
	color: $gray;
	line-height: 24px;
	margin-bottom: 5px;
	display: block;
	@include media-breakpoint-only(lg) {
		margin-bottom: 10px;
	}
}

.featured-news__link {
	font-size: 16px;
	font-weight: $bold;
	color: $color-primary;
	line-height: 24px;
	@include hover {
		text-decoration: underline;
	}
	@include media-breakpoint-only(lg) {
		font-size: 14px;
	}
	@include media-breakpoint-down(sm) {
		font-size: 14px;
	}
}
