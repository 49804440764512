/*--------------------------------------------------------------
## selectric
--------------------------------------------------------------*/

.selectric-wrapper {
	width: 100%;
	position: relative;
	cursor: pointer;
}

.selectric {
	width: 100%;
	position: relative;
	background-color: $white;
	border: 1px solid $gray-lightest;
	.loaded & {
		transition: $duration $ease;
	}
	@include hover {
		border-color: $pink;
	}
	.label {
		font-family: $font-default;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		padding-left: 10px;
		padding-right: 40px;
		font-size: 14px;
		font-weight: $light;
		color: $color-primary;
		line-height: 38px;
		height: 38px;
		text-align: left;
		display: block;
	}
}

.selectric-field-error {
	border-color: #edb5c0;
}

.selectric-arrow {
	position: absolute;
	right: 14px;
	top: 16px;
	font-size: 10px;
	color: $aqua;
	.loaded & {
		transition: $duration $ease;
	}
}

.selectric-open {
	z-index: 40;
	.selectric {
		border-bottom: 0;
		@include hover {
			border-color: $gray-lightest;
		}
		.selectric-arrow {
			transform: rotate(-180deg);
		}
	}
	.selectric-items {
		display: block;
	}
}

.selectric-disabled {
	opacity: 0.5;
	cursor: default;
	user-select: none;
}

.selectric-hide-select {
	position: relative;
	overflow: hidden;
	width: 0;
	height: 0;
	select {
		position: absolute;
		left: -100%;
		display: none;
	}
}

.selectric-input {
	position: absolute !important;
	top: 0 !important;
	left: 0 !important;
	overflow: hidden !important;
	clip: rect(0, 0, 0, 0) !important;
	margin: 0 !important;
	padding: 0 !important;
	width: 1px !important;
	height: 1px !important;
	outline: none !important;
	border: none !important;
	background: none !important;
}

.selectric-temp-show {
	position: absolute !important;
	visibility: hidden !important;
	display: block !important;
}

.selectric-items {
	display: none;
	width: auto;
	position: absolute;
	top: 100%;
	left: 0;
	right: 0;
	background-color: #fdfdfd;
	border: 1px solid $gray-lightest;
	border-top: 0;
	z-index: -1;
	.selectric-scroll {
		height: 100%;
		max-height: 300px;
		overflow-y: auto;
	}
	ul {
		margin-bottom: 0;
	}
	li {
		font-size: 14px;
		font-weight: $light;
		color: $color-primary;
		line-height: 38px;
		padding: 0 10px;
		display: block;
		cursor: pointer;
		.loaded & {
			transition: $duration $ease;
		}
		&:first-of-type {
			display: none;
		}
		@include hover {
			background-color: $gray-lightest;
		}
		&.selected,
		&.highlighted {
			background-color: $gray-lightest;
		}
	}
}

.selectric-form__input--error {
	.selectric {
		border-color: #edb5c0;
	}
	.label {
		color: #bf060e;
	}
}
