/*--------------------------------------------------------------
## signpost-3
--------------------------------------------------------------*/

.signpost-3 {
	padding: 90px 0 30px;
	.homepage & {
		overflow: hidden;
	}
	@include media-breakpoint-only(lg) {
		padding: 40px 0 0;
	}
	@include media-breakpoint-only(md) {
		padding: 12px 0 10px;
		.wrap {
			padding-left: 7.5px;
			padding-right: 7.5px;
		}
	}
	@include media-breakpoint-down(sm) {
		padding: 30px 0 20px;
	}
}

.signpost-3__item {
	position: relative;
	height: calc(100% - 73px);
	padding-bottom: 34px;
	box-shadow: 0 0 25px rgba($black, 0.15);
	margin-bottom: 73px;
	@include hover {
		.icon-link {
			text-decoration: underline;
		}
	}
	@include media-breakpoint-only(lg) {
		padding-bottom: 26px;
	}
	@include media-breakpoint-only(md) {
		height: calc(100% - 40px);
		padding-bottom: 30px;
		margin-bottom: 40px;
		.mixed-modules & {
			margin-left: 62.5px;
			margin-right: 62.5px;
		}
	}
	@include media-breakpoint-down(sm) {
		height: auto;
		padding-bottom: 22px;
		margin-bottom: 20px;
	}
}

.signpost-3__image {
	height: 255px;
	width: 100%;
	overflow: hidden;
	position: relative;
	@include gradient-line;
	&::after {
		height: 5px;
	}
	@include media-breakpoint-only(lg) {
		height: 176px;
	}
	@include media-breakpoint-only(md) {
		height: 352px;
	}
	@include media-breakpoint-down(sm) {
		height: 157px;
	}
}

.signpost-3__content {
	padding: 24px 30px 44px;
	background-color: $white;

	@include media-breakpoint-only(lg) {
		padding: 17px 20px 52px;
	}
	@include media-breakpoint-only(md) {
		padding: 27px 32px 44px;
	}
	@include media-breakpoint-down(sm) {
		padding: 16px 18px 34px 22px;
	}
}

.signpost-3__title {
	color: $silver-darkest;
	font-size: 30px;
	line-height: 30px;
	letter-spacing: 0.45px;
	margin-bottom: 16px;
	font-family: $font-bebas;
	font-weight: normal;
	@include media-breakpoint-between(md, lg) {
		margin-bottom: 8px;
	}
}

.signpost-3__text {
	color: $silver-darkest;
	font-size: 14px;
	line-height: 24px;
	@include media-breakpoint-only(md) {
		max-width: 400px;
	}
}

.signpost-3__cta {
	position: absolute;
	bottom: 34px;
	left: 30px;
	margin-bottom: 0;
	@include media-breakpoint-only(lg) {
		bottom: 26px;
		left: 20px;
	}
	@include media-breakpoint-only(md) {
		bottom: 30px;
		left: 32px;
	}
	@include media-breakpoint-down(sm) {
		bottom: 22px;
		left: 22px;
	}
}

.signpost-3__item--narrow {
	@include media-breakpoint-up(xl) {
		padding-bottom: 20px;
		.signpost-3__image {
			height: 185px;
		}
		.signpost-3__content {
			padding: 20px 25px 50px;
			.signpost-3__cta {
				bottom: 20px;
				left: 25px;
			}
		}
	}
	@include media-breakpoint-only(lg) {
		padding-bottom: 25px;
		.signpost-3__image {
			height: 130px;
		}
		.signpost-3__content {
			padding: 20px 15px 46px;
			.signpost-3__cta {
				bottom: 25px;
				left: 15px;
			}
		}
	}
}
