/*--------------------------------------------------------------
## our-management
--------------------------------------------------------------*/

.our-management {
	position: relative;
	height: calc(100% - 60px);
	padding-bottom: 42px;
	margin-bottom: 60px;
	.icon-link {
		position: absolute;
		bottom: 0;
		left: 0;
	}
	@include media-breakpoint-only(lg) {
		height: calc(100% - 30px);
		margin-bottom: 30px;
	}
	@include media-breakpoint-only(md) {
		height: auto;
		padding-left: 50px;
		padding-right: 50px;
		margin-bottom: 55px;
		.icon-link {
			left: 50px;
		}
	}
	@include media-breakpoint-down(sm) {
		height: auto;
		padding-bottom: 30px;
		margin-bottom: 26px;
	}
}

.our-management__title-wrap {
	display: flex;
	align-items: flex-end;
	margin-bottom: 20px;
}

.our-management__title {
	width: 100%;
	padding-bottom: 18px;
	position: relative;
	margin-bottom: 20px;
	font-size: 30px;
	line-height: 30px;
	letter-spacing: -0.7px;
	display: flex;
	align-items: flex-end;
	&::after {
		content: '';
		display: block;
		background: $gradient-line;
		position: absolute;
		height: 2px;
		left: 0;
		right: 0;
		bottom: 0;
	}
	@include media-breakpoint-only(lg) {
		padding-bottom: 8px;
	}
	@include media-breakpoint-down(sm) {
		font-size: 20px;
		line-height: 28px;
	}
}

.our-management__image {
	margin-bottom: 22px;
	@include media-breakpoint-down(lg) {
		margin-bottom: 20px;
	}
}

.our-management__text {
	font-size: 14px;
	line-height: 20px;
}
