/*--------------------------------------------------------------
## downloads
--------------------------------------------------------------*/

.downloads {
	@include media-breakpoint-only(md) {
		padding: 0 50px;
	}
}

.downloads__header {
	padding-top: 6px;
	@include media-breakpoint-down(md) {
		position: relative;
		display: flex;
		justify-content: flex-end;
		padding-top: 0;
	}
}

.downloads__toggle {
	position: relative;
	min-width: 180px;
	background-color: #fdfdfd;
	padding-left: 15px;
	padding-right: 40px;
	margin-bottom: 20px;
	font-size: 14px;
	font-weight: $light;
	color: $color-primary;
	line-height: 38px;
	height: 40px;
	text-align: left;
	border: 1px solid $gray-lightest;
	display: inline-block;
	.loaded & {
		transition: $duration $ease;
	}
	&::after {
		@include font-styles;
		content: font-char(ico-chevron-down);
		position: absolute;
		right: 16px;
		top: 16px;
		font-size: 10px;
		color: $color-secondary;
		.loaded & {
			transition: $duration $ease;
		}
	}
	@include media-breakpoint-up(lg) {
		display: none;
	}
	@include media-breakpoint-down(sm) {
		width: 100%;
	}
}

.downloads__toggle--active {
	border-color: $color-primary;
	&::after {
		transform: rotate(-180deg);
	}
}

.downloads__links {
	display: flex;
	@include media-breakpoint-down(md) {
		position: absolute;
		top: 40px;
		right: 0;
		min-width: 180px;
		background-color: #fdfdfd;
		border: 1px solid $color-primary;
		border-top: 0;
		z-index: 20;
		display: none;
	}
	@include media-breakpoint-down(sm) {
		width: 100%;
	}
}

.downloads__links-item {
	margin-right: 70px;
	&:last-of-type {
		margin-right: 0;
	}
	@include media-breakpoint-down(md) {
		margin-right: 0;
	}
}

.downloads__link {
	font-size: 16px;
	color: $color-primary;
	line-height: 24px;
	letter-spacing: 0.44px;
	position: relative;
	padding: 2px 30px 18px 2px;
	display: block;
	&::after {
		content: '';
		position: absolute;
		bottom: -1px;
		left: 0;
		width: 100%;
		height: 3px;
		background-color: transparent;
		.loaded & {
			transition: $duration $ease;
		}
	}
	@include hover {
		&::after {
			background-color: $color-secondary;
		}
	}
	@include media-breakpoint-down(md) {
		width: 100%;
		font-size: 14px;
		color: $color-primary;
		line-height: 38px;
		padding: 0 15px;
		text-align: left;
		letter-spacing: 0;
		&::after {
			display: none;
		}
	}
}

.downloads__link--active {
	font-weight: $bold;
	&::after {
		background-color: $color-primary;
	}
	@include media-breakpoint-down(md) {
		font-weight: $light;
		background-color: $gray-lightest;
	}
}

.downloads__list {
	margin-bottom: 72px;
	@include media-breakpoint-only(lg) {
		margin-bottom: 24px;
	}
	@include media-breakpoint-only(md) {
		margin-bottom: 20px;
	}
}

.downloads__item {
	display: flex;
	padding: 20px 16px 30px;
	border-top: 1px solid #e8e8e8;
	&:first-of-type {
		border-color: $gray-light;
	}
	@include media-breakpoint-only(lg) {
		padding-bottom: 24px;
	}
	@include media-breakpoint-only(md) {
		padding-bottom: 6px;
	}
	@include media-breakpoint-down(sm) {
		padding: 20px 0 8px;
		display: block;
	}
}

.downloads__date {
	font-size: 14px;
	color: $gray;
	line-height: 24px;
	padding-right: 20px;
	flex: 0 0 212px;
	@include media-breakpoint-only(lg) {
		flex-basis: 148px;
	}
	@include media-breakpoint-only(md) {
		flex-basis: 112px;
	}
	@include media-breakpoint-down(sm) {
		padding-right: 0;
		margin-bottom: 10px;
		display: block;
	}
}

.downloads__description {
	padding-right: 20px;
	flex: 1 1 auto;
	@include media-breakpoint-down(sm) {
		padding-right: 0;
		margin-bottom: 20px;
	}
}

.downloads__title-link {
	font-size: 14px;
	font-weight: $bold;
	color: $black;
	line-height: 24px;
	margin-bottom: 6px;
	@include hover {
		text-decoration: underline;
	}
}

.downloads__text {
	font-size: 12px;
	line-height: 20px;
	max-width: 420px;
	margin-bottom: 0;
}

.downloads__cta-links {
	flex: 0 0 200px;
	.icon-link {
		color: $blacker;
		display: block;
	}
	@include media-breakpoint-only(lg) {
		flex-basis: 210px;
	}
	@include media-breakpoint-only(md) {
		flex-basis: 164px;
	}
}
