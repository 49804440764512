/*--------------------------------------------------------------
## header--full
--------------------------------------------------------------*/

.header--full {
	height: 100px;
	& ~ .main {
		padding-top: 100px;
	}
	.logo {
		margin-top: 18px;
	}
	.nav-toggle {
		position: relative;
		top: auto;
		right: auto;
		display: block;
	}
	@include media-breakpoint-down(sm) {
		height: 70px;
		& ~ .main {
			padding-top: 70px;
		}
		.logo {
			margin-top: 8px;
			max-width: 110px;
		}
	}
}

