//---------- standard-content
//------------------------------------------------------------------------------

.standard-content {
	margin-bottom: 120px;
	max-width: 790px;
	@include media-breakpoint-only(lg) {
		padding-top: 40px;
	}
	@include media-breakpoint-only(md) {
		padding: 0 50px;
	}
	@include media-breakpoint-down(sm) {
		padding-top: 0;
		margin-bottom: 40px;
	}
}
