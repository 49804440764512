/*--------------------------------------------------------------
## language-switcher
--------------------------------------------------------------*/

.language-switcher {
	position: relative;
	width: 90px;
	z-index: 10;
	&::after {
		content: '';
		position: absolute;
		right: 0;
		top: 0;
		width: 1px;
		height: 20px;
		background-color: $black;
	}
	@include media-breakpoint-down(sm) {
		right: 0;
		width: 80px;
		display: none;
	}
}

.language-switcher__toggle {
	position: relative;
	width: 100%;
	font-size: 15px;
	font-weight: $bold;
	color: $black;
	line-height: 42px;
	text-align: left;
	text-transform: uppercase;
	letter-spacing: 0.4px;
	padding-left: 25px;
	padding-right: 10px;
	z-index: 10;
	display: block;
	.loaded & {
		transition: $duration $ease;
	}
	&::after {
		@include font-styles;
		content: font-char(ico-chevron-down);
		font-size: 11px;
		color: inherit;
		margin-left: 7px;
		display: inline-block;
	}
	@include hover {
		background-color: $silver-light;
	}
	@include media-breakpoint-down(sm) {
		padding-left: 16px;
	}
}

.language-switcher__list {
	position: absolute;
	top: 0;
	left: -1px;
	width: calc(100% + 2px);
	padding-top: 42px;
	background-color: $white;
	border: 1px solid $gray-lightest;
	box-shadow: 0 0 10px -4px rgba($black, 0.3);
	z-index: 5;
	display: none;
}

.language-switcher__item--selected {
	display: none;
}

.language-switcher__link {
	font-size: 15px;
	font-weight: $bold;
	color: $black;
	line-height: 42px;
	text-transform: uppercase;
	letter-spacing: 0.4px;
	padding: 0 25px;
	display: block;
	.loaded & {
		transition: $duration $ease;
	}
	@include hover {
		background-color: $silver-light;
	}
	@include media-breakpoint-down(sm) {
		padding: 0 16px;
	}
}

.header--plain {
	.language-switcher {
		top: -6px;
		&::after {
			display: none;
		}
	}
}

.header--simple {
	.language-switcher {
		top: -10px;
		margin-left: -2px;
		&::after {
			top: 10px;
		}
	}
}
