/*--------------------------------------------------------------
## header--simple
--------------------------------------------------------------*/

.header--simple {
	height: 130px;
	& ~ .main {
		padding-top: 130px !important; //sass-lint:disable-line no-important
	}
	.logo {
		margin-bottom: 5px;
	}
	.header__container {
		padding-top: 4px;
	}
	.header__addons {
		display: flex;
		justify-content: flex-end;
	}
	@include media-breakpoint-between(md, lg) {
		height: 100px;
		& ~ .main {
			padding-top: 100px !important;//sass-lint:disable-line no-important
		}
	}
	@include media-breakpoint-down(lg) {
		.header__addons {
			padding-top: 11px;
			padding-right: 40px;
		}
	}
	@include media-breakpoint-only(md) {
		.logo {
			max-width: 150px;
		}
	}
	@include media-breakpoint-down(sm) {
		height: 70px;
		& ~ .main {
			padding-top: 70px !important;//sass-lint:disable-line no-important
		}
		.logo {
			max-width: 110px;
		}
		.header__container {
			padding-top: 0;
		}
		.header__addons {
			padding-top: 6px;
		}
	}

	.header__links {
		position: relative;
		padding-right: 25px;
		// padding-top: 2px;
		letter-spacing: 0.2px;
		margin-bottom: 20px;
		&::after {
			content: '';
			position: absolute;
			right: 0;
			top: 0;
			width: 1px;
			height: 20px;
			background-color: $black;
		}
		.copyright__link {
			color: $color-primary;
		}
	}
	.header__link-holder {
		padding: 0 25px;
		position: relative;
		display: flex;
		@include media-breakpoint-down(md) {
			display: none;
		}
	}
	.header__link {
		margin-right: 50px;
		padding-top: 2px;
		color: $black;
		&:last-child {
			margin-right: 0;
		}
		&::after {
			content: '';
			position: absolute;
			right: -25px;
			top: 0;
			width: 1px;
			height: 20px;
			background-color: $black;
		}
	}
}
