/*--------------------------------------------------------------
## news-3
--------------------------------------------------------------*/

.news-3 {
	@include media-breakpoint-only(md) {
		padding-left: 50px;
		padding-right: 50px;
		.row {
			[class*='col-'] {
				&:nth-child(3) {
					display: none;
				}
			}
		}
	}
}

.news-3__article {
	position: relative;
	height: calc(100% - 60px);
	overflow: hidden;
	padding-bottom: 100%;
	margin-bottom: 60px;
	@include media-breakpoint-only(lg) {
		.col-lg-3 & {
			padding-bottom: 120%;
		}
	}
	@include media-breakpoint-only(md) {
		height: calc(100% - 40px);
		margin-bottom: 40px;
		.mixed-modules & {
			margin-left: 62.5px;
			margin-right: 62.5px;
		}
	}
	@include media-breakpoint-down(sm) {
		padding-bottom: 0;
		height: auto;
		margin-bottom: 30px;
	}
}

.news-3__container {
	position: absolute;
	bottom: 0;
	left: 0;
	padding: 20px 30px;
	color: $white;
	z-index: 10;
	@include media-breakpoint-only(lg) {
		padding: 15px 20px;
	}
	@include media-breakpoint-down(md) {
		padding: 26px 30px;
	}
	@include media-breakpoint-down(sm) {
		position: relative;
		bottom: auto;
		left: auto;
		padding: 158px 20px 12px;
	}
}

.news-3__tag {
	font-weight: $bold;
	color: $white;
	line-height: 22px;
	text-transform: uppercase;
	margin-right: 12px;
}

.news-3__date {
	color: $white;
	line-height: 22px;
	text-transform: uppercase;
	@include media-breakpoint-only(lg) {
		padding-top: 4px;
		display: block;
	}
}

.news-3__title {
	padding-top: 15px;
	font-weight: $light;
	color: $white;
	@include media-breakpoint-between(md, lg) {
		padding-top: 5px;
	}
	@include media-breakpoint-down(sm) {
		padding-top: 10px;
	}
}
