/*--------------------------------------------------------------
## events
--------------------------------------------------------------*/

.events {
	position: relative;
	@include media-breakpoint-only(md) {
		padding: 0 50px;
	}
	@include media-breakpoint-down(sm) {
		padding-top: 68px;
	}
}

.events__title {
	margin-bottom: 20px;
	font-size: 20px;
	line-height: 28px;
	font-family: $font-default;
	text-transform: initial;
	color: $black;
	letter-spacing: 0;
	font-weight: 700;
	@include media-breakpoint-only(md) {
		margin-bottom: 25px;
	}
}

.events__filter {
	position: absolute;
	top: -12px;
	right: 0;
	min-width: 150px;
	@include media-breakpoint-only(md) {
		right: 50px;
	}
	@include media-breakpoint-down(sm) {
		top: 0;
		width: 100%;
	}
}

.events__list {
	margin-bottom: 72px;
	@include media-breakpoint-down(sm) {
		margin-bottom: 20px;
	}
}

.events__item {
	display: flex;
	padding: 20px 16px 25px;
	border-top: 1px solid #e8e8e8;
	@include media-breakpoint-only(lg) {
		padding-bottom: 10px;
	}
	@include media-breakpoint-only(md) {
		padding-bottom: 20px;
	}
	@include media-breakpoint-down(sm) {
		padding: 20px 0 10px;
		display: block;
	}
}

.events__date {
	padding-right: 38px;
	text-align: center;
	flex: 0 0 212px;
	@include media-breakpoint-only(lg) {
		flex-basis: 148px;
	}
	@include media-breakpoint-only(md) {
		padding-right: 30px;
		flex-basis: 114px;
	}
	@include media-breakpoint-down(sm) {
		padding-right: 0;
		margin-bottom: 18px;
		text-align: left;
	}
}

.events__date-day {
	font-size: 45px;
	font-weight: $bold;
	color: $color-secondary;
	line-height: 54px;
	margin-top: -8px;
	display: block;
}

.events__date-month {
	font-size: 14px;
	color: $color-secondary;
	line-height: 24px;
	margin-top: -4px;
	display: block;
}

.events__description {
	padding-right: 20px;
	flex: 1 1 auto;
	@include media-breakpoint-only(md) {
		margin-top: -5px;
	}
	@include media-breakpoint-down(sm) {
		padding-right: 0;
		margin-bottom: 15px;
	}
}

.events__description-title {
	font-size: 14px;
	font-weight: $bold;
	line-height: 24px;
	margin-bottom: 6px;
	color: $black;
}

.events__description-text {
	font-size: 12px;
	line-height: 20px;
	max-width: 420px;
	margin-bottom: 0;
}

.events__links {
	padding-top: 2px;
	flex: 0 0 198px;
	@include media-breakpoint-only(lg) {
		flex-basis: 212px;
	}
	@include media-breakpoint-only(md) {
		margin-top: -6px;
		flex-basis: 162px;
	}
	@include media-breakpoint-down(sm) {
		.cta-link {
			margin-right: 18px;
		}
	}
}
