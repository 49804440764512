/*--------------------------------------------------------------
## contact-1
--------------------------------------------------------------*/

.contact-1 {
	display: flex;
	max-width: 77.5%;
	margin: 43px 0 40px;
	@include media-breakpoint-only(lg) {
		max-width: 87%;
	}
	@include media-breakpoint-only(md) {
		max-width: 100%;
		padding-left: 50px;
		padding-right: 50px;
	}
	@include media-breakpoint-down(sm) {
		max-width: 100%;
		display: block;
	}
}

.contact-1__map-container {
	position: relative;
	height: 314px;
	flex: 1 1 auto;
	@include media-breakpoint-only(lg) {
		height: 222px;
	}
	@include media-breakpoint-only(md) {
		height: 208px;
	}
	@include media-breakpoint-down(sm) {
		width: 100%;
		height: 158px;
	}
}

.contact-1__map {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.contact-1__content {
	padding: 42px 15px 42px 30px;
	flex: 1 0 228px;
	max-width: 228px;
	@include media-breakpoint-only(lg) {
		padding: 0 15px 20px 26px;
	}
	@include media-breakpoint-only(md) {
		padding: 0 15px 20px 30px;
		flex: 1 0 258px;
		max-width: 258px;
	}
	@include media-breakpoint-down(sm) {
		padding: 16px 0 0;
		width: 100%;
	}
}

.contact-1__address {
	font-style: normal;
	p {
		font-size: 16px;
		font-weight: normal;
		color: $color-primary;
		line-height: 24px;
		letter-spacing: 0.44px;
		&:last-of-type {
			margin-bottom: 28px;
		}
	}
	.icon-link {
		margin-bottom: 6px;
		float: left;
		clear: both;
		font-weight: normal;
	}
}

.contact-1__title {
	font-size: 20px;
	line-height: 23px;
	margin-bottom: 20px;
	text-transform: initial;
	font-family: $font-default;
	font-weight: $bold;
	@include media-breakpoint-between(md, lg) {
		margin-top: -3px;
	}
}
