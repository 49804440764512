/*--------------------------------------------------------------
## contact-2
--------------------------------------------------------------*/

.contact-2 {
	width: 100%;
	margin-bottom: 30px;
	@include media-breakpoint-only(md) {
		padding: 0 50px;
	}
}

.contact-2__item {
	margin-bottom: 40px;
}

.contact-2__map-container {
	position: relative;
	width: 100%;
	height: 185px;
	@include media-breakpoint-only(lg) {
		height: 130px;
	}
	@include media-breakpoint-only(md) {
		height: 136px;
	}
	@include media-breakpoint-down(sm) {
		height: 158px;
	}
}

.contact-2__map {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.contact-2__content {
	width: 100%;
	padding-top: 26px;
}

.contact-2__address {
	font-style: normal;
	p {
		font-size: 16px;
		font-weight: normal;
		color: $color-primary;
		line-height: 24px;
		letter-spacing: 0.44px;
		&:last-of-type {
			margin-bottom: 28px;
		}
	}
	.icon-link {
		margin-bottom: 6px;
		float: left;
		clear: both;
		font-weight: normal;
	}
}

.contact-2__title {
	font-size: 20px;
	line-height: 23px;
	text-transform: initial;
	font-family: $font-default;
	margin-bottom: 20px;
	font-weight: $bold;
}
