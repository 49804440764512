/*--------------------------------------------------------------
## navigation-full
--------------------------------------------------------------*/

.navigation-full {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100vh;
	padding-top: 100px;
	background-color: $color-primary;
	opacity: 0;
	visibility: hidden;
	.loaded & {
		transition: $duration $ease;
	}
	.main-nav__link {
		margin-left: -11px;
		font-size: 20px;
		font-weight: $bold;
		color: $white;
		letter-spacing: -0.5px;
		display: inline-block;
		@include hover {
			text-decoration: underline;
		}
	}
	.main-nav__secondary-link {
		font-size: 13px;
		color: $white;
		letter-spacing: 0.4px;
		display: inline-block;
		@include hover {
			text-decoration: underline;
		}
	}
	.main-nav__tertiary-link {
		font-size: 13px;
		color: $white;
		letter-spacing: 0.3px;
		display: inline-block;
		@include hover {
			text-decoration: underline;
		}
	}
	@include media-breakpoint-up(xl) {
		padding-bottom: 40px;
		.navigation {
			max-height: 100%;
		}
		.main-nav__item {
			padding-top: 70px;
			padding-bottom: 52px;
		}
		.main-nav__secondary-list {
			padding-top: 18px;
			margin-left: -12px;
		}
		.main-nav__secondary-item {
			margin-bottom: 20px;
			&::before {
				@include font-styles;
				content: font-char(ico-long-arrow);
				font-size: 12px;
				color: $white;
				margin-right: 5px;
				position: relative;
				top: 2px;
				display: inline-block;
			}
		}
		.main-nav__tertiary-list {
			padding-top: 20px;
		}
		.main-nav__tertiary-item {
			margin-bottom: 20px;
			padding-left: 28px;
		}
	}
	@include media-breakpoint-down(lg) {
		.navigation__close-btn {
			display: none;
		}
		.row {
			margin: 0;
			display: block;
		}
		.main-nav {
			padding: 0;
		}
		.main-nav__link,
		.main-nav__secondary-link,
		.main-nav__tertiary-link {
			padding: 17px 25px 16px;
			font-size: 14px;
			font-weight: $light;
			color: $black;
			letter-spacing: -0.1px;
			margin-left: 0;
			display: block;
		}
	}
	@include media-breakpoint-down(sm) {
		.main-nav__link,
		.main-nav__secondary-link,
		.main-nav__tertiary-link {
			padding: 17px 20px 16px;
		}
	}
}

.navigation__close-btn {
	position: absolute;
	top: 40px;
	right: 50px;
	font-size: 20px;
	color: $white;
	.loaded & {
		transition: $duration-fast $ease;
	}
	@include hover {
		transform: scale(1.2);
	}
}
