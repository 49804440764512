/*--------------------------------------------------------------
## variables
--------------------------------------------------------------*/
$font-default: 'Poppins', Arial, Helvetica, sans-serif;
$font-secondary: 'Bragest', Arial, Helvetica, sans-serif;
$font-outlined: 'Outlined', 'Poppins', Arial, Helvetica, sans-serif;
$font-bebas: 'Bebas', 'Poppins', Arial, Helvetica, sans-serif;

$light: 300;
$regular: 400;
$medium: 500;
$bold: 700;

// Colors
$white: #fff;
$silver-light: #f1f1f1;
$silver-lightest: #f7f7f7;
$silver: #4a4a4a;
$silver-dark: #2c2c2c;
$silver-darkest: #383a35;
$gray-lightest: #d8d8d8;
$gray-light: #b2b2b2;
$gray: #737373;
$gray-dark: #1b262b;
$black: #000;
$blacker: #000002;
$orange: #f28a22;
$orange-dark: #f47525;
$yellow: #f5b224;
$red: #e9332b;
$red-dark: #da2050;
$aqua: #30b9be;
$aqua-light: #59b7b3;
$aqua-icons: #009193;
$pink: #ee4c9b;


$color-primary: $gray-dark;
$color-secondary: $gray-light;
$color-hover: $orange;

$gradient: linear-gradient(90deg, $red-dark 0%, $orange-dark 100%);
$revert-gradient: linear-gradient(to right, $red-dark 0%, $orange-dark 40%, $orange-dark 60%, $red-dark 100%);
$gradient-line: linear-gradient(90deg, $pink 0%, $yellow 100%);

$site-colors: (
	'black': $black,
	'white': $white,
	'orange': $orange
) !default;

// Transitions
$ease: ease-in-out;
$duration: 0.3s;
$duration-slow: 0.6s;
$duration-fast: 0.15s;

//----------  Bootstrap grid
//------------------------------------------------------------------------------

$enable-grid-classes: true !default;

//----------  Media queries breakpoints - Grid breakpoint
//------------------------------------------------------------------------------

$grid-breakpoints: (
		xs: 0,
		sm: 320px,
		md: 768px,
		lg: 970px,
		xl: 1366px
) !default;

//----------  Grid containers
//------------------------------------------------------------------------------

$container-max-widths: (
		sm: 540px,
		md: 720px,
		lg: 960px,
		xl: 1140px
) !default;

//----------  Grid columns
//------------------------------------------------------------------------------

$grid-columns: 12 !default;
$grid-gutter-width: 15px !default;
