/*--------------------------------------------------------------
## media-library
--------------------------------------------------------------*/

.media-library {
	display: flex;
	flex-wrap: wrap;
	padding-top: 28px;
	padding-right: 10px;
	margin: 0 -15px 100px;
	@include media-breakpoint-down(lg) {
		margin-left: -7.5px;
		margin-right: -7.5px;
		padding-right: 0;
	}
	@include media-breakpoint-only(lg) {
		padding-top: 40px;
	}
	@include media-breakpoint-only(md) {
		padding: 0 50px;
		margin-bottom: 50px;
	}
	@include media-breakpoint-down(sm) {
		padding-top: 0;
		margin: 0 0 30px;
	}
}

.media-library__item {
	flex: 1 1 33.333%;
	max-width: 33.333%;
	padding: 0 7.5px;
	margin-bottom: 18px;
	@include media-breakpoint-only(md) {
		flex: 1 1 50%;
		max-width: 50%;
	}
	@include media-breakpoint-down(sm) {
		flex: 1 1 100%;
		max-width: 100%;
		padding: 0;
		margin-bottom: 18px;
	}
}

.media-library__thumbnail {
	position: relative;
	overflow: hidden;
	padding-bottom: 68.15%;
	margin-bottom: 20px;
	@include media-breakpoint-only(lg) {
		margin-bottom: 10px;
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 14px;
	}
}

.media-library__link {
	padding-left: 22px;
	@include media-breakpoint-down(md) {
		margin-bottom: 20px;
	}
}

.media-library__image-button {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10;
}

.media-library__image {
	max-width: 1068px;
	@include media-breakpoint-only(lg) {
		max-width: 100%;
	}
	@include media-breakpoint-down(md) {
		max-width: 100%;
	}
}

.media-library__popup {
	padding-top: 50px;
	padding-bottom: 45px;
	.popup__close-btn {
		top: 14px;
		right: 10px;
	}
	@include media-breakpoint-only(md) {
		padding: 70px 20px 20px;
		.popup__close-btn {
			top: 24px;
			right: 14px;
		}
	}
	@include media-breakpoint-down(sm) {
		padding-top: 56px;
		padding-bottom: 20px;
	}
}

.media-library__image-container {
	text-align: center;
}

.media-library__info {
	padding-top: 38px;
	@include media-breakpoint-only(md) {
		padding-top: 18px;
	}
	@include media-breakpoint-down(sm) {
		padding-top: 20px;
	}
}

.media-library__image-title {
	margin-bottom: 20px;
	font-size: 26px;
	line-height: 28px;
	text-transform: uppercase;
	color: $silver-darkest;
	font-family: $font-bebas;
	font-weight: normal;
	letter-spacing: normal;
	@include media-breakpoint-only(md) {
		margin-bottom: 12px;
	}
	@include media-breakpoint-down(sm) {
		font-size: 16px;
		line-height: 24px;
		margin-bottom: 10px;
	}
}
