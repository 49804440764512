/*--------------------------------------------------------------
## footer
--------------------------------------------------------------*/

.footer {
	width: 100%;
	color: $white;
	overflow: hidden;
	.loaded & {
		transition: $duration $ease;
	}
}

// .footer--fixed {
// 	position: absolute;
// 	bottom: 0;
// 	left: 0;
// }

.footer__logo {
	max-width: 200px;
	display: inline-block;
	@include media-breakpoint-down(lg) {
		max-width: 150px;
	}
	@include media-breakpoint-down(md) {
		margin-bottom: 42px;
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 52px;
		text-align: center;
	}
}

.footer__top {
	background-color: $silver-darkest;
	padding: 50px 0 55px;
	@include media-breakpoint-only(lg) {
		.row {
			margin-left: -10px;
			margin-right: -10px;
		}
		[class*='col-'] {
			padding-left: 10px;
			padding-right: 10px;
		}
	}
	@include media-breakpoint-only(md) {
		padding-top: 35px;
		padding-bottom: 44px;
		.wrap {
			padding: 0 27px;
		}
	}
	@include media-breakpoint-down(sm) {
		padding-top: 50px;
		padding-bottom: 70px;
		text-align: center;
	}
}

.footer__quick-links {
	@include media-breakpoint-down(sm) {
		margin-bottom: 38px;
	}
}

.footer-nav__title {
	margin-bottom: 14px;
	font-size: 16px;
	font-weight: $bold;
	letter-spacing: 0.6px;
	@include media-breakpoint-down(sm) {
		margin-bottom: 44px;
		text-align: center;
	}
}

.footer-nav__list {
	display: flex;
	flex-wrap: wrap;
	@include media-breakpoint-down(sm) {
		text-align: center;
	}
}

.footer-nav__item {
	flex: 1 1 100%;
}

.footer-nav__link {
	margin-bottom: 14px;
	font-size: 12px;
	font-weight: $medium;
	color: $white;
	letter-spacing: 0.8px;
	display: inline-block;
	@include hover {
		text-decoration: underline;
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 34px;
		font-size: 14px;
	}
}

.footer__newsletter {
	@include media-breakpoint-down(md) {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 38px;
		.footer-nav__title {
			margin-bottom: 0;
			font-size: 18px;
			letter-spacing: -0.5px;
		}
	}
	@include media-breakpoint-down(sm) {
		flex-wrap: wrap;
		margin-top: 10px;
		.footer-nav__title {
			width: 100%;
			margin: 0 0 24px;
		}
	}
}

.footer-nav__form {
	position: relative;
	padding-top: 6px;
	@include media-breakpoint-down(md) {
		padding-top: 0;
	}
}

.footer__mailchimp {
	.text {
		color: $white;
	}
	@include media-breakpoint-down(md) {
		max-width: 432px;
		width: 100%;
		margin-right: 44px;
	}
	@include media-breakpoint-down(sm) {
		margin: 0;
	}
}

.footer-nav__form-email {
	height: 41px;
	width: 100%;
	padding: 0 60px 0 12px;
	font-size: 14px;
	font-weight: $medium;
	color: $white;
	letter-spacing: -0.2px;
	border: 1px solid $color-secondary;
	background-color: $silver-dark;
	&::placeholder {
		color: $color-secondary;
	}
	@include media-breakpoint-down(sm) {
		padding-left: 20px;
	}
}

.footer-nav__form-submit {
	width: 48px;
	height: 41px;
	font-size: 14px;
	color: $white;
	background-color: $color-secondary;
	position: absolute;
	top: 6px;
	right: 0;
	padding-top: 2px;
	.loaded & {
		transition: $duration $ease;
	}
	@include hover {
		background-color: $white;
		color: $color-primary;
	}
	@include media-breakpoint-down(md) {
		top: 0;
	}
}

.footer__bottom {
	position: relative;
	padding: 32px 0 35px;
	background-color: $silver-darkest !important; //sass-lint:disable-line no-important
	@include media-breakpoint-only(md) {
		padding: 30px 0 11px;
	}
	@include media-breakpoint-down(sm) {
		padding: 43px 0 13px;
	}
}

.footer__social-wrap {
	display: flex;
	justify-content: space-between;
}

.footer-socials {
	display: flex;
	justify-content: center;
	@include media-breakpoint-down(md) {
		flex-wrap: wrap;
	}
}

.footer-socials__title {
	margin-right: 12px;
	margin-left: 64px;
	font-size: 22px;
	letter-spacing: 0.9px;
	font-family: $font-bebas;
	font-weight: normal;
	@include media-breakpoint-only(lg) {
		margin-right: 0;
		margin-left: 0;
	}
	@include media-breakpoint-down(md) {
		width: 100%;
		margin: 0 0 21px;
		text-align: center;
		flex: 1 1 100%;
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 39px;
	}
}

.footer-socials__list {
	display: flex;
	padding-top: 3px;
	@include media-breakpoint-only(md) {
		flex-wrap: wrap;
		width: 92%;
		padding-left: 14%;
	}
	@include media-breakpoint-down(sm) {
		flex-wrap: wrap;
		width: 100%;
		padding-top: 2px;
	}
}

.footer-socials__list-item {
	margin-left: 62px;
	@include media-breakpoint-only(lg) {
		margin-left: 64px;
	}
	@include media-breakpoint-only(md) {
		flex: 0 0 33.33333%;
		max-width: 33.33333%;
		margin-left: 0;
		margin-bottom: 24px;
	}
	@include media-breakpoint-down(sm) {
		flex: 0 0 100%;
		max-width: 100%;
		margin-bottom: 30px;
		margin-left: 0;
		text-align: center;
	}
}

.footer-socials__list-link {
	font-size: 14px;
	line-height: 22px;
	font-weight: normal;
	color: $white;
	letter-spacing: 0.8px;
	@include hover {
		text-decoration: underline;
		.footer-socials__list-icon {
			color: $aqua-icons;
		}
	}
	@include media-breakpoint-down(sm) {
		font-size: 16px;
	}
}

.footer-socials__list-icon {
	font-size: 11px;
	color: $white;
	margin-right: 4px;
	@include media-breakpoint-down(sm) {
		font-size: 13px;
		margin-right: 6px;
	}
}

.copyright {
	padding: 20px 0 18px;
	background-color: $black !important;//sass-lint:disable-line no-important
	@include media-breakpoint-only(lg) {
		.wrap {
			padding: 0 20px;
		}
	}
	@include media-breakpoint-only(md) {
		padding-top: 22px;
		padding-bottom: 26px;
	}
	@include media-breakpoint-down(sm) {
		padding-top: 32px;
		padding-bottom: 26px;
	}
}

.copyright__wrap {
	display: flex;
	justify-content: space-between;
	@include media-breakpoint-down(md) {
		flex-wrap: wrap;
	}
}

.copyright__text {
	position: relative;
	font-size: 14px;
	font-weight: $bold;
	line-height: 21px;
	letter-spacing: normal;
	@include media-breakpoint-only(lg) {
		margin-top: 2px;
		font-size: 11px;
	}
	@include media-breakpoint-down(md) {
		width: 100%;
		font-size: 11px;
		margin-bottom: 25px;
		text-align: center;
	}
	@include media-breakpoint-down(sm) {
		font-size: 13px;
		margin-bottom: 40px;
	}
}

.copyright__nav {
	@include media-breakpoint-down(md) {
		margin: 0 auto;
	}
}

.copyright__list {
	display: flex;
	@include media-breakpoint-only(lg) {
		display: block;
	}
	@include media-breakpoint-down(md) {
		display: block;
		line-height: 20px;
	}
	@include media-breakpoint-down(sm) {
		flex-wrap: wrap;
		justify-content: center;
		margin: 0 -14px;
	}
}

.copyright__item {
	position: relative;
	margin-left: 28px;
	&::before {
		content: '';
		position: absolute;
		top: 2px;
		right: -14px;
		width: 1px;
		height: 14px;
		background-color: $white;
	}
	&:last-of-type {
		&::before {
			display: none;
		}
	}
	@include media-breakpoint-up(lg) {
		&:first-of-type {
			margin-left: 0;
		}
	}
	@include media-breakpoint-only(lg) {
		margin-left: 24px;
		display: inline-block;
	}
	@include media-breakpoint-down(lg) {
		&::before {
			top: 4px;
			height: 12px;
		}
	}
	@include media-breakpoint-down(md) {
		margin-left: 20px;
		display: inline-block;
		&::before {
			content: '';
			right: -12px;
		}
	}
	@include media-breakpoint-down(sm) {
		margin: 0 0 14px;
		padding: 0 14px;
		display: inline-block;
		&::before {
			top: 3px;
			right: -1px;
		}
	}
}

.copyright__link {
	font-size: 14px;
	font-weight: normal;
	color: $white;
	line-height: 15px;
	letter-spacing: 0.8px;
	@include hover {
		text-decoration: underline;
	}
	@include media-breakpoint-down(lg) {
		font-size: 11px;
	}
	@include media-breakpoint-down(sm) {
		font-size: 14px;
		letter-spacing: 0;
	}
}

.copyright__link--arrow {
	position: relative;
	padding-left: 15px;
	overflow: hidden;
	&::before {
		@include font-styles;
		content: font-char(ico-up-right);
		position: absolute;
		display: inline-block;
		font-size: 8px;
		margin-top: 1px;
		left: -7px;
		top: 4px;
		margin-left: 7px;
		text-decoration: underline;
	}
}

.copyright__link--arrow::before {
	text-decoration: none;
}
.footer--simplified {
	.footer__bottom {
		padding: 50px 0 39px;
		@include media-breakpoint-only(lg) {
			padding: 38px 0 25px;
		}
		@include media-breakpoint-only(md) {
			padding: 50px 0 34px;
		}
		@include media-breakpoint-down(sm) {
			padding-top: 50px;
			padding-bottom: 12px;
		}
	}
	.footer-socials {
		justify-content: flex-end;
		@include media-breakpoint-only(lg) {
			flex: 0 0 66%;
			max-width: 66%;
		}
		@include media-breakpoint-down(md) {
			flex: 0 0 100%;
			max-width: 100%;
			margin-left: 0;
		}
	}
	.footer__social-wrap {
		@include media-breakpoint-down(md) {
			display: block;
		}
		@include media-breakpoint-down(sm) {
			text-align: center;
		}
	}
	.footer-socials__title {
		margin: 0 12px 0 0;
		text-transform: uppercase;
		@include media-breakpoint-between(md, lg) {
			flex: 0 0 25%;
			max-width: 25%;
			margin: 0;
		}
		@include media-breakpoint-down(md) {
			text-align: left;
		}
		@include media-breakpoint-down(sm) {
			flex: 0 0 100%;
			max-width: 100%;
			text-align: center;
			margin-bottom: 42px;
			margin-right: 0;
		}
	}
	.footer-socials__list {
		padding-top: 7px;
		@include media-breakpoint-down(lg) {
			padding-top: 2px;
			padding-left: 4px;
			flex-wrap: wrap;
			flex: 0 0 75%;
			max-width: 75%;
		}
		@include media-breakpoint-down(md) {
			padding-left: 0;
		}
		@include media-breakpoint-down(sm) {
			flex: 0 0 100%;
			max-width: 100%;
		}
	}
	.footer-socials__list-item {
		margin-left: 64px;
		@include media-breakpoint-between(md, lg) {
			margin-left: 0;
			margin-bottom: 14px;
			flex: 1 1 33.333%;
			max-width: 33.333%;
		}
		@include media-breakpoint-down(sm) {
			flex: 0 0 100%;
			max-width: 100%;
			margin: 0 0 32px;
		}
	}
	.footer-socials__list-link {
		letter-spacing: 0.6px;
	}
	.copyright__text {
		@include media-breakpoint-only(lg) {
			margin-left: 6px;
		}
		@include media-breakpoint-only(md) {
			margin: 0 0 20px;
		}
		@include media-breakpoint-down(sm) {
			margin: 0 0 40px;
		}
	}
	.copyright__list {
		@include media-breakpoint-down(lg) {
			margin-right: 5px;
		}
		@include media-breakpoint-down(sm) {
			margin-right: -14px;
		}
	}
}
