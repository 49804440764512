/*--------------------------------------------------------------
## share-price
--------------------------------------------------------------*/

.share-price {
	position: relative;
	padding-right: 25px;
	// padding-top: 2px;
	letter-spacing: 0.2px;
	margin-bottom: 16px;
	&::after {
		content: '';
		position: absolute;
		right: 0;
		top: 0;
		width: 1px;
		height: 20px;
		background-color: $black;
	}
	@include media-breakpoint-down(lg) {
		padding-top: 0;
		padding-right: 24px;
		letter-spacing: 0;
	}
	@include media-breakpoint-down(sm) {
		display: none;
	}
}

.share-price__text {
	letter-spacing: 0.2px;
	font-weight: normal;
	color: $black;
}

.share-price__text-value {
	padding-right: 1px;
	padding-left: 2px;
	font-size: 16px;
	font-weight: 700;
	letter-spacing: normal;
	color: $black;
}

.share-price__value-holder {
	color: $black;
}

.header--plain {
	.share-price {
		display: none;
	}
}
