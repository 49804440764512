/*--------------------------------------------------------------
## navigation
--------------------------------------------------------------*/

.navigation-megamenu {
	.main-nav__secondary-back {
		display: none;
	}
	@include media-breakpoint-up(xl) {
		.navigation {
			padding-right: 12px;
			margin-top: 8px;
		}
		.main-nav__list {
			display: flex;
		}
		.main-nav__link {
			position: relative;
			//padding-bottom: 25px;
			font-size: 16px;
			font-weight: normal;
			text-align: center;
			color: $black;
			line-height: 36px;
			letter-spacing: normal;
			&::after {
				content: '';
				position: absolute;
				bottom: -29px;
				left: 0;
				right: 0;
				width: 100%;
				height: 5px;
				background: linear-gradient(to right, $pink, $yellow);
				visibility: hidden;
				opacity: 0;
				.loaded & {
					transition: 0.3s ease-in-out;
				}
			}
			@include hover {
				&::after {
				 visibility: visible;
				 opacity: 1;
				}
			}
		}
		.main-nav__item {
			padding-left: 53px;
			&.main-nav__has-children {
				.main-nav__link::before {
					display: block;
					visibility: hidden;
					opacity: 0;
					content: '';
					position: absolute;
					bottom: -25px;
					left: 50%;
					transform: translateX(-50%);
					height: 0;
					width: 0;
					border: solid transparent;
					border-bottom-color: $pink;
					border-width: 8px;
					pointer-events: none;
					.loaded & {
						transition: 0.3s ease-in-out;
					}
				}
				@include hover {
					> .main-nav__link::before {
						display: block;
						visibility: visible;
						opacity: 1;
					}
					> .main-nav__secondary-list {
						display: flex;
						visibility: visible;
						opacity: 1;
					}
				}
			}
		}
		.main-nav__secondary-links {
			padding-left: 60px;
		}
		.main-nav__secondary-list {
			display: flex;
			opacity: 0;
			visibility: hidden;
			position: absolute;
			top: 125px;
			left: 50%;
			transform: translateX(-50%);
			width: 100vw;
			padding-top: 42px;
			padding-bottom: 65px;
			background-color: $white;
			border-bottom: 1px solid rgba($color-primary, 0.3);
			box-shadow: 0 2px 4px -3px rgba($color-primary, 0.6),
			0 4px 14px -4px rgba($color-primary, 0.3);
			overflow: hidden;
			.loaded & {
				transition: 0.3s ease-in-out;
			}
			&::before {
				content: '';
				width: 100%;
				position: absolute;
				top: 0;
				left: 0;
				background: linear-gradient(to right, $pink, $yellow);
				display: block;
				height: 5px;
				z-index: 10;
			}
		}
		.main-nav__secondary-item {
			position: relative;
			margin-top: 26px;
			margin-bottom: 4px;
			padding-right: 45px;
			letter-spacing: 0.5px;
			font-size: 15px;
			&.main-nav__has-children {
				&::after {
					@include font-styles;
					content: font-char(ico-chevron-down);
					position: absolute;
					top: 7px;
					right: 30px;
					font-size: 14px;
					transform: rotate(-90deg);
					pointer-events: none;
					color: $aqua-light;
				}
			}
			&.main-nav__item--opened {
				max-width: 100%;
				flex: 0 0 100%;
				&::after {
					display: none;
				}
				.main-nav__secondary-back {
					position: absolute;
					top: -2px;
					left: -4px;
					width: 30px;
					height: 30px;
					font-size: 14px;
					display: block;
					.icon {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%) rotate(90deg);
						color: $aqua-light;
					}
				}
				.main-nav__secondary-link {
					margin-left: 20px;
					font-weight: 600;
					letter-spacing: 0.3px;
				}
				.main-nav__tertiary-list {
					display: flex;
				}
			}
		}
		.main-nav__secondary-link {
			color: $black;
			font-size: 16px;
			line-height: 26px;
			font-weight: normal;
			display: block;
			@include hover {
				text-decoration: underline;
			}
		}
		.main-nav__featured {
			padding-left: 4px;
			padding-right: 52px;
			color: $color-primary;
			z-index: 1;
			&::before {
				content: '';
				position: absolute;
				top: -42px;
				right: 0;
				z-index: -1;
				height: calc(100% + 108px);
				width: 100vw;
				background-color: $silver-light;
			}
		}
		.main-nav__featured-title {
			margin-bottom: 13px;
			font-size: 24px;
			font-weight: normal;
			line-height: 28px;
			letter-spacing: normal;
			color: $silver-darkest;
			text-transform: uppercase;
			font-family: $font-bebas;
		}
		.main-nav__featured-text {
			font-size: 16px;
			line-height: 28px;
			letter-spacing: 0.44px;
			color: $silver-darkest;
		}
		.main-nav__tertiary-list {
			display: none;
			flex-flow: row wrap;
			width: 100%;
			min-height: 100%;
			margin-top: 21px;
			&::before {
				content: '';
				position: absolute;
				top: 42px;
				left: -50vw;
				width: 200vw;
				height: 1px;
				background-color: $color-secondary;
			}
		}
		.main-nav__tertiary-item {
			position: relative;
			margin-top: 24px;
			margin-bottom: 2px;
			letter-spacing: 0.5px;
			font-size: 15px;
		}
		.main-nav__tertiary-link {
			color: $black;
			font-size: 16px;
			line-height: 26px;
			font-weight: normal;
			@include hover {
				text-decoration: underline;
			}
		}
		.main-nav__list-opened {
			.main-nav__secondary-item:not(.main-nav__item--opened) {
				display: none;
			}
		}
	}
	@media only screen and (min-width: 1366px) and (max-width: 1680px) {
		.main-nav__item {
			padding-left: 40px;
		}
	}
}

.header--plain {
	.navigation-megamenu {
		.main-nav__secondary-list {
			top: 82px;
		}
	}
}
