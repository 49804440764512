/*--------------------------------------------------------------
## lists
--------------------------------------------------------------*/

.rte {
	li {
		position: relative;
		font-size: 18px;
		font-weight: $light;
		line-height: 30px;
	}
	ul {
		&:not(.tabs__links) {
			padding-left: 28px;
		}
		li {
			&:not(.tabs__links-item) {
				margin-bottom: 30px;
				&::before {
					content: '';
					position: absolute;
					top: 13px;
					left: -25px;
					width: 4px;
					height: 4px;
					border-radius: 50%;
					background-color: $aqua-light;
				}
			}
		}
		ul {
			&:not(.tabs__links) {
				padding-left: 56px;
				padding-top: 30px;
				li {
					&:not(.tabs__links-item) {
						margin-bottom: 30px;
						&::before {
							content: '';
							position: absolute;
							top: 14px;
							left: -28px;
							width: 8px;
							height: 2px;
							background-color: $aqua-light;
						}
					}
				}
			}
		}
	}
	ol {
		padding-left: 28px;
		counter-reset: ol-list;
		margin-bottom: 40px;
		li {
			margin-bottom: 30px;
			&::before {
				counter-increment: ol-list;
				content: counter(ol-list)'.';
				position: absolute;
				color: $aqua-light;
				top: 0;
				left: -28px;
			}
		}
	}
	@include media-breakpoint-down(sm) {
		li {
			font-size: 16px;
			line-height: 26px;
		}
		ol,
		ul {
			margin-bottom: 30px;
		}
	}
}
