/*--------------------------------------------------------------
## search
--------------------------------------------------------------*/

.search {
	position: relative;
	padding: 2px 16px 0 25px;
	margin-top: -4px;
	@include media-breakpoint-down(lg) {
		padding: 0 16px 0 24px;
	}
	@include media-breakpoint-down(sm) {
		padding-right: 0;
		margin-top: 0;
	}
}

.search__open-btn {
	font-size: 21px;
	color: $black;
	.loaded & {
		transition: color $duration $ease;
	}
	@include hover {
		color: $color-hover;
	}
}

.search__logo {
	position: absolute;
	top: 34px;
	left: 50px;
	@include media-breakpoint-only(lg) {
		left: 26px;
	}
	@include media-breakpoint-only(md) {
		left: 20px;
	}
	@include media-breakpoint-down(sm) {
		top: 26px;
		left: 20px;
		max-width: 120px;
	}
}

.search__popup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	min-height: 100vh;
	background-color: $aqua-light;
	display: none;
	z-index: 150;
	.search__form-input {
		box-shadow: inset 0 0 0 1000px $aqua-light;
	}
}

.search__close-btn {
	position: absolute;
	top: 40px;
	right: 50px;
	font-size: 20px;
	color: $white;
	.loaded & {
		transition: $duration-fast $ease;
	}
	@include hover {
		transform: scale(1.2);
	}
	@include media-breakpoint-only(lg) {
		right: 32px;
	}
	@include media-breakpoint-only(md) {
		right: 24px;
	}
	@include media-breakpoint-down(sm) {
		top: 26px;
		right: 20px;
	}
}

.search__form {
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	width: 100%;
	max-width: 906px;
	margin: -25px auto 0;
	@include media-breakpoint-between(md, lg) {
		max-width: 680px;
	}
	@include media-breakpoint-down(sm) {
		left: 20px;
		right: 20px;
		width: auto;
	}
}

.search__form-input {
	width: 100%;
	height: 50px;
	padding-left: 60px;
	border: 0;
	border-bottom: 1px solid $white;
	background-color: transparent;
	font-size: 28px;
	color: $white;
	letter-spacing: 0.8px;
	-webkit-text-fill-color: $white; // sass-lint:disable-line no-vendor-prefixes
	font-weight: 200;
	&::placeholder {
		color: rgba($white, 0.6);
		font-weight: 200;
	}
	@include media-breakpoint-down(sm) {
		padding-left: 45px;
		font-size: 20px;
	}
}

.search__form-clear {
	position: absolute;
	top: 8px;
	right: 10px;
	width: 40px;
	height: 40px;
	font-size: 14px;
	color: $white;
	.loaded & {
		transition: $duration-fast $ease;
	}
	@include hover {
		transform: scale(1.2);
	}
	@include media-breakpoint-down(sm) {
		right: 0;
	}
}

.search__form-submit {
	position: absolute;
	left: 4px;
	top: 2px;
	width: 50px;
	height: 50px;
	font-size: 20px;
	color: $white;
	z-index: 5;
	.loaded & {
		transition: $duration-fast $ease;
	}
	@include hover {
		transform: scale(1.2);
	}
	@include media-breakpoint-down(sm) {
		width: 30px;
	}
}


.header--plain {
	.search {
		margin-right: -3px;
		padding: 0 0 0 16px;
	}
	.search__open-btn {
		font-size: 28px;
	}
	@include media-breakpoint-only(md) {
		.search {
			padding-left: 2px;
		}
	}
	@include media-breakpoint-down(sm) {
		.search {
			margin: 6px 0 0;
		}
	}
}

.header--full {
	.search {
		padding: 5px 0 0;
	}
	.search__open-btn {
		margin-right: -3px;
		font-size: 28px;
		@include media-breakpoint-down(lg) {
			margin-right: 0;
		}
		@include media-breakpoint-down(sm) {
			font-size: 22px;
			margin-top: 3px;
		}
	}
}
