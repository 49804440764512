/*--------------------------------------------------------------
## pagination
--------------------------------------------------------------*/

.pagination {
	display: flex;
	justify-content: center;
	margin-bottom: 62px;
	margin-right: 22px;
	@include media-breakpoint-only(lg) {
		margin-bottom: 50px;
		margin-right: 110px;
	}
	@include media-breakpoint-down(sm) {
		margin-right: 0;
		margin-bottom: 40px;
	}
}

.pagination__list {
	display: flex;
}

.pagination__item {
	margin: 0 6px;
	@include media-breakpoint-down(sm) {
		margin: 0 2px;
	}
}

.pagination__btn {
	font-size: 16px;
	color: $color-primary;
	line-height: 24px;
	display: inline-block;
	@include gradient-line;
	position: relative;
	&::after {
		height: 5px;
		background: transparent;
	}
	.loaded & {
		transition: $duration $ease;
	}
	@include hover {
		&::after {
			background: $gradient-line;
		}
	}
}

.pagination__link {
	padding: 0 8px 12px;
}

.pagination__link--active {
	font-weight: $bold;
	@include gradient-line;
	&::after {
		height: 5px;
		background: $gradient-line;
	}
}

.pagination__arrow-link {
	position: relative;
	font-weight: $bold;
	padding-bottom: 12px;
	.icon {
		font-size: 12px;
		color: $pink;
		position: absolute;
		top: 5px;
		.loaded & {
			transition: $duration $ease;
		}
	}
	@include hover {
		.icon {
			color: $color-primary;
		}
	}
}

.pagination__prev {
	margin-right: 30px;
	padding-left: 22px;
	.icon {
		left: 0;
		transform: rotate(180deg);
	}
	@include media-breakpoint-down(sm) {
		margin-right: 5px;
	}
}

.pagination__next {
	margin-left: 30px;
	padding-right: 22px;
	.icon {
		right: 0;
	}
	@include media-breakpoint-down(sm) {
		margin-left: 5px;
	}
}

.pagination__btn--disabled {
	opacity: 0.4;
	cursor: default;
	@include hover {
		&::after {
			background: transparent;
		}
		.icon {
			color: $color-secondary;
		}
	}
}
