/**
 * @license
 * MyFonts Webfont Build ID 3808703, 2019-09-18T10:38:16-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: BebasNeue-Regular by Dharma Type
 * URL: https://www.myfonts.com/fonts/flat-it/bebas-neue/regular/
 * Copyright: Copyright &#x00A9; 2010 by Dharma Type.
 * Licensed pageviews: Unlimited
 *
 *
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3808703
 *
 * © 2019 MyFonts Inc
*/

$font-name: Poppins;
$font-name2: Bragest;
$font-name3: Outlined;
$font-name4: Bebas;
$font-path: '../../assets/fonts/';

@font-face {
	font-family: '' + $font-name + '';
	src: url($font-path + 'Poppins-Thin.woff2') format('woff2'),
		url($font-path + 'Poppins-Thin.woff') format('woff'),
		url($font-path + 'Poppins-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: '' + $font-name + '';
	src: url($font-path + 'Poppins-ThinItalic.woff2') format('woff2'),
		url($font-path + 'Poppins-ThinItalic.woff') format('woff'),
		url($font-path + 'Poppins-ThinItalic.ttf') format('truetype');
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: '' + $font-name + '';
	src: url($font-path + 'Poppins-ExtraLight.woff2') format('woff2'),
		url($font-path + 'Poppins-ExtraLight.woff') format('woff'),
		url($font-path + 'Poppins-ExtraLight.ttf') format('truetype');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: '' + $font-name + '';
	src: url($font-path + 'Poppins-ExtraLightItalic.woff2') format('woff2'),
		url($font-path + 'Poppins-ExtraLightItalic.woff') format('woff'),
		url($font-path + 'Poppins-ExtraLightItalic.ttf') format('truetype');
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: '' + $font-name + '';
	src: url($font-path + 'Poppins-Light.woff2') format('woff2'),
		url($font-path + 'Poppins-Light.woff') format('woff'),
		url($font-path + 'Poppins-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: '' + $font-name + '';
	src: url($font-path + 'Poppins-LightItalic.woff2') format('woff2'),
		url($font-path + 'Poppins-LightItalic.woff') format('woff'),
		url($font-path + 'Poppins-LightItalic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: '' + $font-name + '';
	src: url($font-path + 'Poppins-Regular.woff2') format('woff2'),
		url($font-path + 'Poppins-Regular.woff') format('woff'),
		url($font-path + 'Poppins-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: '' + $font-name + '';
	src: url($font-path + 'Poppins-Italic.woff2') format('woff2'),
		url($font-path + 'Poppins-Italic.woff') format('woff'),
		url($font-path + 'Poppins-Italic.ttf') format('truetype');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: '' + $font-name + '';
	src: url($font-path + 'Poppins-Medium.woff2') format('woff2'),
		url($font-path + 'Poppins-Medium.woff') format('woff'),
		url($font-path + 'Poppins-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: '' + $font-name + '';
	src: url($font-path + 'Poppins-MediumItalic.woff2') format('woff2'),
		url($font-path + 'Poppins-MediumItalic.woff') format('woff'),
		url($font-path + 'Poppins-MediumItalic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: '' + $font-name + '';
	src: url($font-path + 'Poppins-SemiBold.woff2') format('woff2'),
		url($font-path + 'Poppins-SemiBold.woff') format('woff'),
		url($font-path + 'Poppins-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: '' + $font-name + '';
	src: url($font-path + 'Poppins-SemiBoldItalic.woff2') format('woff2'),
		url($font-path + 'Poppins-SemiBoldItalic.woff') format('woff'),
		url($font-path + 'Poppins-SemiBoldItalic.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: '' + $font-name + '';
	src: url($font-path + 'Poppins-Bold.woff2') format('woff2'),
		url($font-path + 'Poppins-Bold.woff') format('woff'),
		url($font-path + 'Poppins-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: '' + $font-name + '';
	src: url($font-path + 'Poppins-BoldItalic.woff2') format('woff2'),
		url($font-path + 'Poppins-BoldItalic.woff') format('woff'),
		url($font-path + 'Poppins-BoldItalic.ttf') format('truetype');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: '' + $font-name2 + '';
	src: url($font-path + 'TheBraggest-Demo.woff2') format('woff2'),
		 url($font-path + 'TheBraggest-Demo.woff') format('woff'),
		 url($font-path + 'TheBraggest_Demo.otf') format('opentype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: '' + $font-name3 + '';
	src: url($font-path + 'ItsPoppinsOutlined-Medium.woff2') format('woff2'),
		url($font-path + 'ItsPoppinsOutlined-Medium.woff') format('woff'),
		url($font-path + 'ItsPoppinsOutlined-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: '' + $font-name3 + '';
	src: url($font-path + 'ItsPoppinsOutlined-Regular.woff2') format('woff2'),
		url($font-path + 'ItsPoppinsOutlined-Regular.woff') format('woff'),
		url($font-path + 'ItsPoppinsOutlined-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: '' + $font-name4 + '';
	src: url($font-path + '3A1DBF_0_0.woff2') format('woff2'),
		 url($font-path + '3A1DBF_0_0.woff') format('woff'),
		 url($font-path + '3A1DBF_0_0.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}
