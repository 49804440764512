/*--------------------------------------------------------------
## header--plain
--------------------------------------------------------------*/

.header--plain {
	height: 100px;
	& ~ .main {
		padding-top: 100px;
	}
	.logo {
		margin-top: -4px;
	}
	.header__container {
		padding-top: 15px;
	}
	.header__actions {
		display: flex;
		flex-direction: row-reverse;
	}
	.header__addons {
		display: flex;
		justify-content: flex-end;
	}
	@include media-breakpoint-only(lg) {
		.header__addons {
			padding: 1px 3px 0 0;
		}
		.nav-toggle {
			right: 162px;
			top: 36px;
		}
	}
	@include media-breakpoint-only(md) {
		.header__addons {
			padding: 0 65px 0 0;
		}
		.nav-toggle {
			right: 16px;
		}
	}
	@include media-breakpoint-down(sm) {
		height: 70px;
		& ~ .main {
			padding-top: 70px;
		}
		.logo {
			margin-top: 8px;
			max-width: 120px;
		}
		.header__container {
			padding-top: 0;
		}
		.header__addons {
			padding: 0 40px 0 0;
		}
		.nav-toggle {
			top: 20px;
			right: 20px;
		}
	}
}
