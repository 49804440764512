/*--------------------------------------------------------------
## key-facts
--------------------------------------------------------------*/

.key-facts {
	position: relative;
	padding-top: 84px;
	padding-bottom: 88px;
	background-color: $color-primary;
	.wrap {
		position: relative;
		z-index: 10;
	}
	&:last-child {
		margin-bottom: 60px;
	}
	@include media-breakpoint-only(lg) {
		padding-top: 44px;
		padding-bottom: 74px;
	}
	@include media-breakpoint-only(md) {
		padding-top: 36px;
		padding-bottom: 2px;
	}
	@include media-breakpoint-down(sm) {
		padding-top: 42px;
		padding-bottom: 50px;
	}
}

.key-facts__title {
	font-size: 50px;
	color: $silver-darkest;
	line-height: 40px;
	text-align: center;
	letter-spacing: normal;
	margin-bottom: 27px;
	font-family: $font-bebas;
	font-weight: normal;
	@include media-breakpoint-only(lg) {
		margin-bottom: 15px;
	}
	@include media-breakpoint-only(md) {
		margin-bottom: 12px;
	}
	@include media-breakpoint-down(sm) {
		font-size: 36px;
		line-height: 36px;
		margin-bottom: 15px;
	}
}

.key-facts__text {
	font-size: 16px;
	line-height: 30px;
	color: $silver-darkest;
	letter-spacing: -0.44px;
	text-align: center;
	max-width: 800px;
	margin: 0 auto 87px;
	@include media-breakpoint-between(md, lg) {
		font-size: 16px;
		line-height: 26px;
	}
	@include media-breakpoint-only(lg) {
		max-width: 710px;
		margin-bottom: 40px;
	}
	@include media-breakpoint-only(md) {
		max-width: 520px;
		margin-bottom: 36px;
	}
	@include media-breakpoint-down(sm) {
		font-size: 16px;
		line-height: 25px;
		margin-bottom: 32px;
	}
}

.key-facts__thumbnail {
	height: 60px;
	margin-bottom: 34px;
	@include media-breakpoint-down(lg) {
		height: 44px;
	}
	@include media-breakpoint-only(lg) {
		margin-bottom: 22px;
	}
	@include media-breakpoint-only(md) {
		margin-bottom: 8px;
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 10px;
	}
}

.key-facts__image {
	max-width: 60px;
	max-height: 100%;
	@include media-breakpoint-between(md, lg) {
		max-width: 44px;
	}
}

.key-facts__list {
	text-align: center;
	@include media-breakpoint-only(md) {
		padding: 0 8.6667%;
	}
}

.key-facts__item {
	position: relative;
	padding-bottom: 42px;
	@include media-breakpoint-down(md) {
		margin-bottom: 52px;
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 62px;
	}
}

.key-facts__item-number {
	font-size: 175px;
	font-weight: 500;
	color: $silver-darkest;
	line-height: 80px;
	letter-spacing: -1.11px;
	margin-bottom: 15px;
	display: block;
	font-family: $font-outlined;
	background: linear-gradient(to right, $pink, $yellow);
	-webkit-text-fill-color: transparent; //sass-lint:disable-line no-vendor-prefixes
	-webkit-background-clip: text; //sass-lint:disable-line no-vendor-prefixes
	background-position: 0 0;
	padding-top: 10px;
	@include media-breakpoint-only(lg) {
		font-size: 125px;
		line-height: 60px;
		margin-bottom: 6px;
		letter-spacing: 0;
	}
	@include media-breakpoint-down(md) {
		font-size: 125px;
		line-height: 60px;
		letter-spacing: 0;
		margin-bottom: 4px;
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 2px;
	}
}

.key-facts__item-number--explorer {
	background: none;
	-webkit-text-fill-color: none; //sass-lint:disable-line no-vendor-prefixes
	color: $pink;
}

.key-facts__item-title {
	color: $silver-darkest;
	margin: 22px 0 28px;
	font-family: $font-secondary;
	font-size: 46px;
	line-height: 37px;
	font-weight: normal;
	text-transform: inherit;
	@include media-breakpoint-only(lg) {
		margin-bottom: 8px;
	}
	@include media-breakpoint-only(md) {
		margin-bottom: 10px;
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 8px;
	}
}

.key-facts__item-text {
	font-size: 16px;
	color: $white;
	line-height: 26px;
	padding: 0 25px;
	margin-bottom: 28px;
	@include media-breakpoint-down(lg) {
		font-size: 14px;
		padding: 0;
	}
	@include media-breakpoint-only(lg) {
		line-height: 24px;
		margin-bottom: 18px;
	}
	@include media-breakpoint-only(md) {
		line-height: 21px;
		margin-bottom: 22px;
	}
	@include media-breakpoint-down(sm) {
		line-height: 21px;
		margin-bottom: 25px;
	}
}

.key-facts__cta-wrap {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
}

.key-facts__cta {
	padding: 12px 30px;
	@include media-breakpoint-down(md) {
		font-size: 14px;
		line-height: 15px;
		text-transform: none;
		letter-spacing: 0;
		&.btn--outline {
			padding: 13px 22px 12px 14px;
		}
	}
	@include media-breakpoint-down(sm) {
		width: auto;
		padding: 13px 22px;
	}
}

.key-facts--figures {
	@include media-breakpoint-between(md, lg) {
		.key-facts__title {
			margin-bottom: 2px;
		}
		.key-facts__text {
			font-size: 18px;
			line-height: 30px;
			max-width: 800px;
			margin-bottom: 36px;
		}
		.key-facts__item-text {
			line-height: 21px;
			margin-bottom: 32px;
		}
		.key-facts__cta {
			font-size: 14px;
			line-height: 15px;
			text-transform: none;
			letter-spacing: 0;
			&.btn--outline {
				padding: 13px 22px 12px 14px;
			}
		}
	}
	@include media-breakpoint-only(md) {
		padding-bottom: 16px;
		.key-facts__title {
			margin-bottom: 10px;
		}
		.key-facts__text {
			max-width: 620px;
			margin-bottom: 14px;
		}
		.key-facts__item {
			margin-bottom: 40px;
		}
		.key-facts__item-text {
			margin-bottom: 22px;
		}
	}
	@include media-breakpoint-down(sm) {
		padding-bottom: 60px;
		.key-facts__text {
			margin-bottom: 25px;
		}
		.key-facts__item {
			margin-bottom: 52px;
		}
	}
}
