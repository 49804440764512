/*--------------------------------------------------------------
## slick
--------------------------------------------------------------*/

.slick-slider {
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
	display: block;
	position: relative;
	touch-action: pan-y;
	user-select: none;
	.slick-track,
	.slick-list {
		transform: translate3d(0, 0, 0);
	}
}

.slick-list {
	display: block;
	margin: 0;
	overflow: hidden;
	padding: 0;
	position: relative;
	&:focus {
		outline: none;
	}
	&.dragging {
		cursor: pointer;
		cursor: hand;
	}
}

.slick-track {
	@extend %clearfix;
	display: block;
	left: 0;
	position: relative;
	top: 0;
	.slick-loading & {
		visibility: hidden;
	}
}

.slick-slide {
	display: none;
	float: left;
	height: 100%;
	min-height: 1px;
	outline: 0;
	[dir='rtl'] & {
		float: right;
	}
	img {
		display: block;
	}
	&.slick-loading img {
		display: none;
	}
	&.dragging img {
		pointer-events: none;
	}
	.slick-initialized & {
		display: block;
	}
	.slick-loading & {
		visibility: hidden;
	}
	.slick-vertical & {
		display: block;
		height: auto;
		border: 1px solid transparent;
	}
}

.slick-arrow {
	&.slick-hidden {
		display: none;
	}
}
