/*--------------------------------------------------------------
## event-3
--------------------------------------------------------------*/

.event-3 {
	@include media-breakpoint-only(md) {
		padding: 0 50px;
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 20px;
	}
}

.event-3__item {
	height: calc(100% - 60px);
	background-color: $white;
	padding: 42px 40px 26px;
	margin-bottom: 60px;
	border-radius: 5px;
	box-shadow: 0 0 15px 0 rgba($black, 0.12);
	@include media-breakpoint-only(lg) {
		padding: 35px 30px 20px;
	}
	@include media-breakpoint-only(md) {
		height: calc(100% - 40px);
		padding: 35px 60px 12px 30px;
		margin-bottom: 40px;
		.mixed-modules & {
			margin-left: 62.5px;
			margin-right: 62.5px;
		}
	}
	@include media-breakpoint-down(sm) {
		height: auto;
		padding: 28px 26px 14px;
		margin-bottom: 30px;
	}
}

.event-3__main-title {
	margin-bottom: 40px;
	@include media-breakpoint-down(sm) {
		font-size: 20px;
		line-height: 28px;
		margin-bottom: 20px;
	}
}

.event-3__category {
	font-weight: $bold;
	text-transform: uppercase;
	margin-right: 20px;
}

.event-3__date {
	text-transform: uppercase;
	white-space: nowrap;
}

.event-3__text {
	font-size: 20px;
	line-height: 28px;
	padding-top: 20px;
	margin-bottom: 48px;
	min-height: 100px;
	@include media-breakpoint-only(lg) {
		margin-bottom: 10px;
	}
	@include media-breakpoint-only(md) {
		margin-bottom: 35px;
	}
	@include media-breakpoint-down(sm) {
		padding-top: 22px;
		min-height: initial;
		margin-bottom: 28px;
	}
}

.event-3__links {
	.cta-link {
		margin-right: 52px;
	}
	@include media-breakpoint-only(lg) {
		.icon-link {
			margin-bottom: 10px;
		}
	}
	@include media-breakpoint-only(md) {
		.cta-link {
			margin-right: 42px;
		}
	}
	@include media-breakpoint-down(sm) {
		.icon-link {
			margin-bottom: 10px;
		}
	}
}

.event-3__item--narrow {
	@include media-breakpoint-up(xl) {
		padding: 32px 32px 15px;
		.event-3__text {
			margin-bottom: 22px;
		}
		.event-3__links {
			.icon-link {
				margin-bottom: 18px;
			}
		}
	}
	@include media-breakpoint-only(lg) {
		padding: 32px 26px 18px;
		.event-3__text {
			margin-bottom: 14px;
		}
	}
}
