.navigation-opened {
	@include media-breakpoint-up(xl) {
		.navigation-full {
			opacity: 1;
			visibility: visible;
		}
		.search,
		.nav-toggle {
			display: none;
		}
		&.header--full {
			.logo {
				margin-left: auto;
				margin-right: auto;
			}
			.logo__image--black {
				opacity: 0;
				visibility: hidden;
			}
			.logo__image--white {
				opacity: 1;
				visibility: visible;
			}
		}
	}
	@include media-breakpoint-down(lg) {
		.navigation-wrap {
			opacity: 1;
			visibility: visible;
		}
		.share-price__value-holder {
			color: $white;
		}
		.share-price__text,
		.share-price__text-value {
			color: $white;
		}
		.share-price {
			position: absolute;
			top: 26px;
			left: 25px;
			padding-right: 0;
			color: $white;
			z-index: 30;
			display: block;
			&::after {
				display: none;
			}
		}
		.share {
			position: absolute;
			right: 82px;
			top: 87px;
			color: $black;
			z-index: 30;
			display: block;
			&::after {
				display: none;
			}
		}
		.language-switcher {
			display: block;
			position: absolute;
			right: 2px;
			top: 76px;
			z-index: 30;
			&::after {
				display: none;
			}
		}
		.language-switcher__toggle {
			color: $black;
		}
		.search {
			display: none;
		}
		.nav-toggle {
			top: 25px;
			right: 25px;
			height: 22px;
			width: 21px;
			z-index: 30;
			.nav-toggle__line {
				width: 22px;
				visibility: hidden;
				opacity: 0;
				background-color: $white;
			}
			&::before,
			&::after {
				width: 22px;
				margin-top: 0;
				background-color: $white;
				.loaded & {
					transition: margin $duration-fast $ease, transform $duration-fast $ease $duration-fast;
				}
			}
			&::before {
				transform: translateY(-50%) rotate(45deg);
			}
			&::after {
				transform: translateY(-50%) rotate(-45deg);
			}
		}
		.nav-toggle__text {
			display: none;
		}
		&.header--plain {
			.share-price {
				display: block;
			}
			.share {
				display: block;
			}
			.language-switcher {
				top: 76px;
			}
		}
		&.header--simple {
			.language-switcher {
				top: 76px;
				margin-left: 0;
			}
		}
		&.header--full {
			.nav-toggle {
				position: absolute;
			}
		}
		.navigation-full {
			height: auto;
			padding-top: 0;
			.navigation {
				padding: 0;
			}
		}
	}
}

.primary-navigation-opened {
	@include media-breakpoint-down(lg) {
		.navigation__back-button {
			display: block;
		}
		.navigation__main-link {
			display: none;
		}
		.main-nav {
			overflow: visible;
		}
		.main-nav__item:not(.main-nav__item--opened) {
			display: none;
		}
		.main-nav__secondary-list {
			left: 0;
			.wrap {
				padding: 0;
			}
			.row {
				margin: 0;
				display: block;
			}
		}
		.main-nav__secondary-links {
			padding-left: 0;
			padding-right: 0;
		}
		.main-nav__item.main-nav__item--opened {
			background-color: $silver-light;
			&.main-nav__has-children {
				&::after {
					display: none;
				}
			}
			.main-nav__link {
				font-size: 16px;
				font-weight: 600;
				letter-spacing: -0.4px;
			}
		}
	}
}

.secondary-navigation-opened {
	@include media-breakpoint-down(lg) {
		.main-nav__secondary-list {
			top: 121px;
			overflow: visible;
		}
		.main-nav__link {
			display: none;
		}
		.main-nav__tertiary-list {
			top: 176px;
			left: 0;
		}
		.main-nav__secondary-item.main-nav__item--opened {
			background-color: $silver-light;
			&.main-nav__has-children {
				&::after {
					display: none;
				}
			}
			.main-nav__secondary-link {
				font-size: 16px;
				font-weight: 600;
				letter-spacing: -0.4px;
			}
		}
		.main-nav__secondary-item:not(.main-nav__item--opened) {
			display: none;
		}
		.main-nav__item--opened {
			> .main-nav__link {
				display: none;
			}
		}
	}
}
