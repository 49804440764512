/*--------------------------------------------------------------
## navigation-simple
--------------------------------------------------------------*/

.navigation-simple {
	@include media-breakpoint-up(xl) {
		.navigation {
			padding-right: 24px;
			margin-top: -4px;
		}
		.main-nav__list {
			display: flex;
		}
		.main-nav__item {
			margin-left: 53px;
		}
		.main-nav__link {
			position: relative;
			padding-bottom: 40px;
			font-size: 14px;
			font-weight: $light;
			text-align: center;
			color: $black;
			line-height: 36px;
			letter-spacing: 1.1px;
			&::after {
				content: '';
				position: absolute;
				bottom: 0;
				left: -15px;
				width: 0;
				height: 5px;
				background-color: $color-primary;
				.loaded & {
					transition: $duration-fast $ease;
				}
			}
		}
		.main-nav__item {
			position: relative;
			@include hover {
				> .main-nav__link::after {
					width: calc(100% + 30px);
				}
				> .main-nav__secondary-list {
					display: block;
				}
			}
		}
		.main-nav__secondary-list {
			display: none;
			position: absolute;
			top: 67px;
			left: 0;
			width: 242px;
			padding: 14px 0;
			background-color: $white;
			border: 1px solid $gray-lightest;
			box-shadow: 0 2px 4px -3px rgba($color-primary, 0.6),
						0 4px 14px -4px rgba($color-primary, 0.3);
		}
		.main-nav__secondary-item {
			padding: 0;
			letter-spacing: 1.1px;
		}
		.main-nav__secondary-link {
			display: block;
			padding: 14px 20px;
			color: $black;
			.loaded & {
				transition: background-color $duration $ease;
			}
			@include hover {
				background-color: $silver-light;
			}
		}
		.main-nav__tertiary-list {
			display: none;
		}
	}
	@media only screen and (min-width: 1366px) and (max-width: 1680px) {
		.main-nav__item {
			margin-left: 40px;
		}
	}
}

.header--dark {
	.navigation-simple {
		@include media-breakpoint-up(xl) {
			.main-nav__item {
				@include hover {
					> .main-nav__link::after {
						background-color: $white;
					}
				}
			}
		}
	}
}
