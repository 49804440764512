/*--------------------------------------------------------------
## share
--------------------------------------------------------------*/

.share {
	position: relative;
	width: 62px;
	&::after {
		content: '';
		position: absolute;
		right: 0;
		top: 0;
		width: 1px;
		height: 20px;
		background-color: $black;
	}
	@include media-breakpoint-down(sm) {
		margin-right: -12px;
		&::after {
			display: none;
		}
	}
}

.share__toggle {
	position: absolute;
	top: -12px;
	left: -1px;
	width: 64px;
	height: 42px;
	padding-top: 6px;
	font-size: 18px;
	z-index: 10;
	.loaded & {
		transition: $duration $ease;
	}
	@include hover {
		background-color: $silver-light;
	}
}

.share__list {
	position: absolute;
	top: -12px;
	left: -1px;
	width: 64px;
	padding-top: 45px;
	padding-bottom: 4px;
	background-color: $white;
	border: 1px solid $gray-lightest;
	box-shadow: 0 0 10px -4px rgba($black, 0.3);
	z-index: 5;
	display: none;
}

.share__item {
	margin-bottom: 2px;
}

.share__link {
	font-size: 18px;
	color: $color-primary;
	line-height: 38px;
	text-align: center;
	padding-top: 4px;
	display: block;
	.loaded & {
		transition: $duration $ease;
	}
	.font-ico-twitter {
		font-size: 22px;
	}
	@include hover {
		background-color: $silver-light;
	}
}

.header--plain {
	.share {
		display: none;
		@include media-breakpoint-down(sm) {
			padding-right: 0;
		}
	}
}
