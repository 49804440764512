/*--------------------------------------------------------------
## floating-block
--------------------------------------------------------------*/

.floating-block {
	padding: 90px 0;
	@include media-breakpoint-only(lg) {
		padding: 60px 0;
	}
	@include media-breakpoint-only(md) {
		padding: 40px 0;
	}
	@include media-breakpoint-down(sm) {
		padding: 30px 0 40px;
	}
}

.floating-block__container {
	padding: 38px 180px 30px 44px;
	border: 1px solid $color-primary;
	margin: 0 8.5%;
	@include media-breakpoint-only(lg) {
		padding: 34px 20px 38px 30px;
	}
	@include media-breakpoint-only(md) {
		padding: 28px 20px 32px 30px;
		margin: 0 6.25%;
	}
	@include media-breakpoint-down(sm) {
		padding: 18px 12px;
		margin: 0;
	}
}

.floating-block__container--right {
	text-align: right;
	padding-left: 180px;
	padding-right: 44px;
	@include media-breakpoint-only(lg) {
		padding-left: 20px;
		padding-right: 30px;
	}
	@include media-breakpoint-only(md) {
		padding-left: 20px;
		padding-right: 30px;
	}
	@include media-breakpoint-down(sm) {
		padding-left: 12px;
		padding-right: 12px;
	}
}

.floating-block__container--center {
	text-align: center;
	padding-left: 110px;
	padding-right: 110px;
	@include media-breakpoint-between(md, lg) {
		padding-left: 22px;
		padding-right: 22px;
	}
	@include media-breakpoint-down(sm) {
		padding-left: 12px;
		padding-right: 12px;
	}
}

.floating-block__title {
	font-size: 28px;
	line-height: 36px;
	margin-bottom: 14px;
	@include media-breakpoint-only(lg) {
		margin-bottom: 10px;
	}
	@include media-breakpoint-down(md) {
		margin-bottom: 16px;
	}
	@include media-breakpoint-down(sm) {
		font-size: 20px;
		line-height: 28px;
		margin-bottom: 10px;
	}
}

.floating-block__text {
	margin-bottom: 20px;
	@include media-breakpoint-only(md) {
		font-size: 16px;
		line-height: 24px;
		letter-spacing: 0.44px;
	}
	@include media-breakpoint-down(sm) {
		font-size: 14px;
		line-height: 24px;
	}
}

