/*--------------------------------------------------------------
## header themes
--------------------------------------------------------------*/

.header--dark {
	background-color: $color-primary;
	.logo__image--black {
		opacity: 0;
	}
	.logo__image--white {
		opacity: 1;
	}
	.search__open-btn {
		color: $white;
	}
	.share__toggle {
		color: $white;
		@include hover {
			color: $black;
		}
	}
	.share__toggle--active {
		color: $black;
	}
	.language-switcher {
		&::after {
			background-color: $white;
		}
	}
	.language-switcher__toggle {
		color: $white;
		@include hover {
			color: $black;
		}
	}
	.language-switcher__toggle--active {
		color: $black;
	}
	.share,
	.share-price {
		color: $white;
		&::after {
			background-color: $white;
		}
	}
	.nav-toggle {
		color: $white;
		&::before,
		&::after {
			background-color: $white;
		}
	}
	.nav-toggle__line {
		background-color: $white;
	}
	.nav-toggle__text {
		color: $white;
	}
	@include media-breakpoint-up(xl) {
		.navigation {
			.main-nav__link {
				color: $white;
			}
		}
		.navigation-megamenu {
			.main-nav__link {
				&::after {
					background-color: $white;
				}
			}
			.main-nav__item.main-nav__has-children {
				.main-nav__link {
					&::before {
						border-bottom-color: $white;
					}
				}
			}
			.main-nav__secondary-list {
				border-top-color: $white;
			}
		}
	}
}

.header--transparent {
	&:not(.header--scrolled) {
		background-color: transparent;
	}
}

.header--homepage {
	& ~ .main {
		padding-top: 0;
	}
}
