/*--------------------------------------------------------------
## signpost-1
--------------------------------------------------------------*/

.signpost-1 {
	padding: 90px 0 30px;
	.homepage & {
		overflow: hidden;
	}
	@include media-breakpoint-only(lg) {
		padding: 40px 0 0;
	}
	@include media-breakpoint-only(md) {
		padding: 60px 0 10px;
		.wrap {
			padding-left: 7.5px;
			padding-right: 7.5px;
		}
	}
	@include media-breakpoint-down(sm) {
		padding: 30px 0 20px;
	}
}

.signpost-1__item {
	position: relative;
	padding: 38px;
	height: calc(100% - 60px);
	box-shadow: 0 0 25px rgba($black, 0.15);
	margin-bottom: 60px;
	@include media-breakpoint-only(lg) {
		padding: 28px 10px 38px 20px;
	}
	@include media-breakpoint-only(md) {
		padding: 28px 38px 28px 30px;
		height: calc(100% - 40px);
		margin-bottom: 40px;
		.mixed-modules & {
			margin-left: 62.5px;
			margin-right: 62.5px;
		}
	}
	@include media-breakpoint-down(sm) {
		padding: 16px 15px 5px;
		height: auto;
		margin-bottom: 20px;
	}
}

.signpost-1__title {
	max-width: 100%;
	width: 100%;
	font-size: 22px;
	line-height: 30px;
	letter-spacing: 0.33px;
	margin-bottom: 26px;
	@include media-breakpoint-only(md) {
		margin-bottom: 26px;
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 12px;
	}
}

.signpost-1__content {
	@extend %clearfix;
	margin-bottom: 58px;
	@include media-breakpoint-only(lg) {
		margin-bottom: 26px;
	}
	@include media-breakpoint-only(md) {
		margin-bottom: 28px;
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 5px;
	}
}

.signpost-1__image {
	max-width: 168px;
	width: 50%;
	border: 1px solid $color-secondary;
	float: left;
	& ~ .signpost-1__text {
		margin-left: calc(50% + 16px);
	}
	@include media-breakpoint-only(lg) {
		width: 45%;
		& ~ .signpost-1__text {
			margin-left: calc(45% + 10px);
		}
	}
	@include media-breakpoint-only(md) {
		max-width: none;
		width: 38%;
		& ~ .signpost-1__text {
			margin-left: calc(38% + 30px);
		}
		& ~ .signpost-1__cta--mobile {
			margin-left: calc(38% + 34px);
		}
	}
	@include media-breakpoint-down(sm) {
		float: none;
		width: 100%;
		max-width: none;
		margin-bottom: 16px;
		& ~ .signpost-1__text {
			margin-left: 0;
		}
	}
}

.signpost-1__text {
	font-size: 14px;
	line-height: 24px;
	@include media-breakpoint-only(lg) {
		padding-top: 0;
		margin-top: -5px;
	}
	@include media-breakpoint-only(md) {
		margin-bottom: 20px;
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 12px;
	}
}

.signpost-1__cta {
	margin-bottom: 0;
	@include media-breakpoint-down(md) {
		display: none;
	}
}

.signpost-1__cta--mobile {
	display: none;
	@include media-breakpoint-down(md) {
		display: block;
	}
}
