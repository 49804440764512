/*--------------------------------------------------------------
## layout
--------------------------------------------------------------*/

body {
	font-family: $font-default;
	font-size: 14px;
	font-weight: $light;
	color: $silver-darkest;
	overscroll-behavior: none; // sass-lint:disable-line no-misspelled-properties
}

.scroll-disabled {
	position: fixed;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.main {
	position: relative;
	width: 100%;
	@include media-breakpoint-between(md, lg) {
		.row {
			margin-left: -7.5px;
			margin-right: -7.5px;
		}
		[class*='col-'] {
			padding-left: 7.5px;
			padding-right: 7.5px;
		}
	}
}

.main--without-banner {
	.wrap {
		padding-top: 90px;
	}
	@include media-breakpoint-only(md) {
		.wrap {
			padding-top: 40px;
		}
	}
	@include media-breakpoint-down(sm) {
		.wrap {
			padding-top: 20px;
		}
	}
}

.sidebar {
	margin-bottom: 80px;
	@include media-breakpoint-down(md) {
		display: none;
	}
}

.main-content {
	@include media-breakpoint-only(md) {
		padding: 0 65px;
	}
}

.wrap {
	@extend %clearfix;
	max-width: 1400px;
	width: 100%;
	margin: 0 auto;
	padding: 0 17px;
	@include media-breakpoint-only(lg) {
		padding: 0 27px;
	}
	@include media-breakpoint-down(md) {
		padding: 0 20px;
	}
}

.gutter-indented {
	.row {
		margin-left: -7.5px;
		margin-right: -7.5px;
	}
	[class*='col-'] {
		padding-left: 7.5px;
		padding-right: 7.5px;
	}
}

.mixed-modules {
	padding: 90px 0 30px;
	.share-price-details {
		padding: 37px 40px 22px;
	}
	.share-price-details__title {
		margin-bottom: 60px;
	}
	.share-price-details__info {
		margin-bottom: 34px;
	}
	.share-price-details__value {
		font-size: 143px;
	}
	@include media-breakpoint-only(lg) {
		padding: 40px 0 0;
		.share-price-details__value {
			font-size: 120px;
		}
	}
	@include media-breakpoint-only(md) {
		padding: 30px 0 10px;
		.wrap {
			padding-left: 7.5px;
			padding-right: 7.5px;
		}
	}
	@include media-breakpoint-down(sm) {
		padding: 30px 0 20px;
		.share-price-details {
			padding: 25px 18px 34px 22px;
		}
		.share-price-details__value {
			font-size: 120px;
			margin-bottom: 15px;
		}
	}
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
img,
ul,
ol,
.connected-module {
	& + .connected-module {
		padding-top: 0;
	}
}
.landing-page {
	> .connected-module:first-child {
		padding-top: 0;
	}
}
.justify-center {
	justify-content: center;
}
