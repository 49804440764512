//---------- share-price-details
//------------------------------------------------------------------------------

.share-price-details {
	height: calc(100% - 73px);
	padding: 36px 24px 18px;
	margin-bottom: 73px;
	background-color: $orange;
	color: $white;
	.icon-link {
		letter-spacing: 0.44px;
		color: $white;
		&::before {
			color: $white;
		}
		@include hover {
			&::before {
				color: $white;
			}
		}
	}
	.col-lg-3 & {
		box-shadow: 0 0 25px rgba($black, 0.15);
	}
	.mixed-modules & {
		position: relative;
		padding-bottom: 34px;
	}
	@include media-breakpoint-up(lg) {
		.mixed-modules & {
			.icon-link {
				position: absolute;
				bottom: 34px;
				margin-bottom: 0;
			}
		}
	}
	@include media-breakpoint-only(lg) {
		padding-left: 25px;
		padding-right: 25px;
		.mixed-modules & {
			.icon-link {
				position: absolute;
				bottom: 26px;
				margin-bottom: 0;
			}
		}
	}
	@include media-breakpoint-only(md) {
		height: calc(100% - 40px);
		margin: 0 50px 40px;
		padding: 28px 30px 32px;
		.mixed-modules & {
			margin-left: 62.5px;
			margin-right: 62.5px;
			// .icon-link {

			// }
		}
	}
	@include media-breakpoint-down(sm) {
		height: auto;
		padding: 25px 20px;
		margin-bottom: 30px;
	}
}

.share-price-details__title {
	font-size: 30px;
	line-height: 28px;
	font-family: $font-bebas;
	font-weight: normal;
	margin-bottom: 30px;
	@include media-breakpoint-only(lg) {
		margin-bottom: 20px;
	}
	@include media-breakpoint-only(md) {
		margin-bottom: 32px;
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 24px;
	}
}

.share-price-details__value {
	font-size: 113px;
	font-weight: 500;
	line-height: 66px;
	letter-spacing: -3px;
	margin-bottom: 17px;
	display: block;
	font-family: $font-outlined;
	sub {
		font-size: 40px;
		font-weight: normal;
		line-height: 60px;
		bottom: 0;
		text-transform: uppercase;
		font-family: $font-secondary;
		margin-left: 10px;
	}
	@include media-breakpoint-only(lg) {
		margin-bottom: 26px;
		.col-lg-3 & {
			font-size: 40px;
			line-height: 48px;
			letter-spacing: -0.44px;
			margin-bottom: 28px;
			sub {
				font-size: 20px;
				line-height: 28px;
				margin-left: 6px;
			}
		}
	}
	@include media-breakpoint-down(md) {
		font-size: 143px;
		line-height: 48px;
		letter-spacing: -0.44px;
		margin-bottom: 28px;
		sub {
			font-size: 20px;
			line-height: 28px;
			margin-left: 12px;
		}
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 15px;
		font-size: 120px;
	}
}

.share-price-details__row {
	width: 100%;
	line-height: 24px;
	margin-bottom: 18px;
	float: left;
	clear: both;
	@include media-breakpoint-down(lg) {
		margin-bottom: 12px;
	}
}

.share-price-details__change {
	font-size: 16px;
	font-weight: $bold;
	line-height: 24px;
	text-align: right;
	float: right;
}

.share-price-details__info {
	padding-top: 8px;
	line-height: 24px;
	margin-bottom: 35px;
	display: block;
	clear: both;
	@include media-breakpoint-only(md) {
		margin-bottom: 46px;
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 30px;
	}
}

.share-price-details--dark {
	background-color: $color-primary;
	.share-price-details__title,
	.share-price-details__value,
	.share-price-details__row,
	.share-price-details__info {
		color: $white;
	}
	.icon-link {
		color: $white;
		@include hover {
			&::before {
				color: $white;
			}
		}
	}
}
