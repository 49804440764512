/*--------------------------------------------------------------
## sidenav
--------------------------------------------------------------*/
.sidenav {
	padding-right: 12px;
}

.sidenav__title {
	font-size: 26px;
	line-height: 29px;
	padding-bottom: 22px;
	margin-bottom: 22px;
	text-transform: uppercase;
	position: relative;
	font-weight: normal;
	letter-spacing: 0;
	@include gradient-line;
}

.sidenav__item {
	margin-bottom: 25px;
}

.sidenav__link {
	font-size: 16px;
	color: $silver-darkest;
	line-height: 25px;
	@include hover {
		text-decoration: underline;
	}
}

.sidenav__link--active {
	font-weight: $bold;
	& + .sidenav__secondary-list {
		display: block;
	}
}

.sidenav__secondary-list {
	padding-top: 28px;
	padding-left: 28px;
	display: none;
}

.sidenav__secondary-item {
	margin-bottom: 28px;
}

.sidenav__secondary-link {
	font-size: 13px;
	color: $color-primary;
	@include hover {
		text-decoration: underline;
	}
}

.sidenav__secondary-link--active {
	font-weight: $bold;
	& + .sidenav__tertiary-list {
		display: block;
	}
}

.sidenav__tertiary-list {
	padding-top: 26px;
	padding-left: 56px;
	display: none;
}

.sidenav__tertiary-item {
	margin-bottom: 28px;
}

.sidenav__tertiary-link {
	font-size: 14px;
	color: $color-primary;
	@include hover {
		text-decoration: underline;
	}
}

.sidenav__tertiary-link--active {
	font-weight: $bold;
}

.sidenav--alternative {
	.sidenav__title {
		padding-bottom: 10px;
		margin-bottom: 0;
		border-color: $color-secondary;
	}
	.sidenav__link {
		padding: 10px 14px;
		border-bottom: 1px solid $color-secondary;
		display: block;
	}
	.sidenav__link--active {
		position: relative;
		&::after {
			content: '';
			position: absolute;
			bottom: -1px;
			left: 0;
			width: 100%;
			height: 4px;
			background-color: $color-primary;
		}
	}
	.sidenav__item {
		margin-bottom: 0;
	}
	.sidenav__secondary-list {
		padding-top: 20px;
		padding-left: 48px;
		padding-bottom: 26px;
		border-bottom: 1px solid $color-secondary;
	}
	.sidenav__secondary-item {
		&:last-of-type {
			margin-bottom: 0;
		}
	}
	.sidenav__tertiary-item {
		&:last-of-type {
			margin-bottom: 0;
		}
	}
}
