/*--------------------------------------------------------------
## financial-calendar
--------------------------------------------------------------*/

.financial-calendar {
	padding-top: 9px;
	@include media-breakpoint-only(lg) {
		padding-top: 3px;
	}
	@include media-breakpoint-only(md) {
		padding: 0 50px;
	}
	@include media-breakpoint-down(sm) {
		padding-top: 0;
	}
}

.financial-calendar__header {
	@include media-breakpoint-down(md) {
		position: relative;
		display: flex;
		justify-content: flex-end;
	}
}

.financial-calendar__toggle {
	position: relative;
	min-width: 180px;
	background-color: #fdfdfd;
	padding-left: 15px;
	padding-right: 40px;
	margin-bottom: 20px;
	font-size: 14px;
	font-weight: $light;
	color: $color-primary;
	line-height: 38px;
	height: 40px;
	text-align: left;
	border: 1px solid $gray-lightest;
	display: inline-block;
	.loaded & {
		transition: $duration $ease;
	}
	&::after {
		@include font-styles;
		content: font-char(ico-chevron-down);
		position: absolute;
		right: 16px;
		top: 16px;
		font-size: 10px;
		color: $color-secondary;
		.loaded & {
			transition: $duration $ease;
		}
	}
	@include media-breakpoint-up(lg) {
		display: none;
	}
	@include media-breakpoint-down(sm) {
		width: 100%;
	}
}

.financial-calendar__toggle--active {
	border-color: $color-primary;
	&::after {
		transform: rotate(-180deg);
	}
}

.financial-calendar__tabs {
	display: flex;
	justify-content: flex-start;
	@include media-breakpoint-down(md) {
		position: absolute;
		top: 40px;
		right: 0;
		min-width: 180px;
		background-color: #fdfdfd;
		border: 1px solid $color-primary;
		border-top: 0;
		z-index: 20;
		display: none;
	}
	@include media-breakpoint-down(sm) {
		width: 100%;
	}
}

.financial-calendar__tab {
	color: $black;
	font-weight: $bold;
	font-size: 16px;
	padding-bottom: 15px;
	padding-right: 20px;
	margin-right: 60px;
	border-bottom: 3px solid transparent;
	cursor: pointer;
	.loaded & {
		transition: $duration $ease;
	}
	@include hover {
		border-color: $color-secondary;
	}
	@include media-breakpoint-down(md) {
		width: 100%;
		font-size: 14px;
		font-weight: $light;
		color: $color-primary;
		line-height: 38px;
		padding: 0 15px;
		margin-right: 0;
		text-align: left;
		letter-spacing: 0;
		border-bottom: 0;
		display: block;
	}
}

.financial-calendar__tab--active {
	border-bottom: 3px solid $black;
	@include media-breakpoint-down(md) {
		background-color: $gray-lightest;
		border-bottom: 0;
	}
}

.financial-calendar__list {
	margin-bottom: 80px;
	@include media-breakpoint-only(md) {
		margin-bottom: 20px;
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 40px;
	}
}

.financial-calendar__list-item {
	display: flex;
	justify-content: flex-start;
	padding: 23px 50px 70px 15px;
	border-top: 1px solid #e8e8e8;
	margin-top: -1px;
	&:first-of-type {
		border-color: $color-secondary;
	}
	@include media-breakpoint-only(md) {
		padding-bottom: 78px;
	}
	@include media-breakpoint-down(sm) {
		padding: 20px 0 28px;
		display: block;
	}
}

.financial-calendar__date {
	flex: 0 0 212px;
	font-size: 14px;
	line-height: 24px;
	font-weight: 300;
	padding-right: 15px;
	color: $gray;
	@include media-breakpoint-only(lg) {
		flex-basis: 150px;
	}
	@include media-breakpoint-only(md) {
		flex-basis: 112px;
	}
	@include media-breakpoint-down(sm) {
		padding-right: 0;
		margin-bottom: 10px;
		display: block;
	}
}

.financial-calendar__title {
	flex: 1 1 auto;
	font-weight: 600;
	color: $color-primary;
	font-size: 14px;
	@include hover {
		text-decoration: underline;
	}
}

.financial-calendar__item {
	flex: 1 1 auto;
	font-weight: $bold;
	color: $color-primary;
}
