/*--------------------------------------------------------------
## popup
--------------------------------------------------------------*/

.popup-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba($black, 0.6);
	display: none;
	z-index: 1000;
}

.popup-overlay--opened {
	.popup {
		margin-top: 0;
	}
}

.popup {
	width: 100%;
	max-width: 1168px;
	padding: 72px 50px 96px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: $white;
	margin-top: -100px;
	.loaded & {
		transition: $duration $ease;
	}
	@include media-breakpoint-between(md, lg) {
		width: calc(100% - 60px);
		left: 30px;
		right: 30px;
		margin: 0 auto;
		transform: translate(0, -50%);
	}
	@include media-breakpoint-only(lg) {
		padding: 50px 30px;
		width: calc(100% - 230px);
	}
	@include media-breakpoint-only(md) {
		padding: 30px;
	}
	@include media-breakpoint-down(sm) {
		width: calc(100% - 40px);
		padding: 56px 30px 40px;
		left: 20px;
		transform: translate(0, -50%);
	}
}

.popup__content {
	display: flex;
	max-height: calc(100vh - 280px);
	overflow-y: auto;
	@include media-breakpoint-down(sm) {
		max-height: calc(100vh - 160px);
		-webkit-overflow-scrolling: touch;// sass-lint:disable-line no-vendor-prefixes
		display: block;
	}
}

.popup__close-btn {
	position: absolute;
	top: 36px;
	right: 36px;
	padding: 6px;
	font-size: 20px;
	color: $aqua-light;
	z-index: 20;
	.icon {
		.loaded & {
			transition: $duration $ease;
		}
	}
	@include hover {
		.icon {
			transform: scale(1.2);
		}
	}
	@include media-breakpoint-only(md) {
		top: 24px;
		right: 12px;
	}
	@include media-breakpoint-down(sm) {
		top: 12px;
		right: 12px;
	}
}

.popup__scroll-arrow {
	position: absolute;
	bottom: 20px;
	left: 50%;
	margin-left: -12px;
	animation: bounce 3s infinite;
	display: none;
	.icon {
		font-size: 20px;
		color: $color-secondary;
	}
	@include media-breakpoint-down(sm) {
		bottom: 20px;
	}
}

.popup__arrow {
	width: 44px;
	height: 44px;
	position: absolute;
	top: 50%;
	margin-top: -22px;
	background-color: $white;
	border: 2px solid $pink;
	border-radius: 50%;
	opacity: 1;
	visibility: visible;
	display: block;
	z-index: 10;
	.loaded & {
		transition: $duration $ease;
	}
	.icon {
		position: absolute;
		top: 50%;
		left: 50%;
		font-size: 18px;
		color: $pink;
		transition: $duration $ease;
	}
	@include hover {
		background-color: $pink;
		.icon {
			color: $white;
		}
	}
}

.popup__arrow--previous {
	left: -22px;
	.icon {
		transform: translate(-50%, -50%) rotate(180deg);
	}
	@include media-breakpoint-down(sm) {
		left: -18px;
	}
}

.popup__arrow--next {
	right: -22px;
	.icon {
		transform: translate(-50%, -50%);
	}
	@include media-breakpoint-down(sm) {
		right: -18px;
	}
}

.popup__arrow--disabled {
	opacity: 0;
	visibility: hidden;
}

.popup__dots {
	position: absolute;
	bottom: 10px;
	left: 0;
	width: 100%;
	font-size: 0;
	text-align: center;
	@include media-breakpoint-down(md) {
		display: none;
	}
	@include media-breakpoint-down(sm) {
		bottom: 12px;
	}
}

.popup__dot {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: rgba($pink, 0.4);
	margin: 0 4px;
	display: inline-block;
	.loaded & {
		transition: $duration $ease;
	}
	@include hover {
		background-color: $pink;
	}
}

.popup__dot--active {
	background-color: $pink;
}

@keyframes bounce {
	0%, 20%, 50%, 80%, 100% {
		transform: translateY(0);
	}
	40% {
		transform: translateY(-18px);
	}
	60% {
		transform: translateY(-8px);
	}
}
