/*--------------------------------------------------------------
## event-1
--------------------------------------------------------------*/

.event-1 {
	@include media-breakpoint-only(md) {
		padding: 0 50px;
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 20px;
	}
}

.event-1__item {
	height: calc(100% - 60px);
	text-align: center;
	margin-bottom: 60px;
	@include media-breakpoint-only(md) {
		height: calc(100% - 40px);
		margin-bottom: 40px;
		.mixed-modules & {
			margin-left: 62.5px;
			margin-right: 62.5px;
		}
	}
	@include media-breakpoint-down(sm) {
		height: auto;
		margin-bottom: 30px;
	}
}

.event-1__container {
	position: relative;
	background-color: $white;
	padding: 15px 10px 64px;
	border-top: 0;
	height: calc(100% - 65px);
	box-shadow: 0 2px 4px 0 rgba($black, 0.1), 0 2px 11px 0 rgba($black, 0.1);
	@include media-breakpoint-only(lg) {
		padding: 10px 25px 78px;
	}
	@include media-breakpoint-only(md) {
		height: auto;
		padding: 22px 60px 16px;
	}
	@include media-breakpoint-down(sm) {
		height: auto;
		padding-top: 10px;
		padding-bottom: 12px;
	}
}

.event-1__main-title {
	margin-bottom: 40px;
	font-size: 20px;
	line-height: 28px;
	letter-spacing: normal;
	font-weight: $bold;
	font-family: $font-default;
	text-transform: initial;
	color: $black;
	@include media-breakpoint-down(sm) {
		font-size: 20px;
		line-height: 28px;
		margin-bottom: 20px;
	}
}

.event-1__month {
	padding: 20px 15px 15px;
	background-color: $orange;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	font-size: 30px;
	font-weight: $bold;
	color: $white;
	line-height: 1;
	text-transform: uppercase;
	letter-spacing: 1.2px;
	display: block;
}

.event-1__date {
	margin-bottom: 15px;
	color: $orange;
	@include media-breakpoint-only(lg) {
		margin-bottom: 10px;
	}
	@include media-breakpoint-only(md) {
		margin-bottom: 14px;
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 10px;
	}
}

.event-1__date-day {
	font-size: 70px;
	font-weight: $bold;
	line-height: 80px;
}

.event-1__date-suffix {
	font-size: 40px;
	font-weight: $bold;
	line-height: 48px;
}

.event-1__text {
	margin-bottom: 32px;
	font-size: 14px;
	line-height: 24px;
	font-weight: normal;
	@include media-breakpoint-only(lg) {
		margin-bottom: 15px;
	}
	@include media-breakpoint-only(md) {
		margin-bottom: 18px;
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 16px;
	}
}

.event-1__links {
	width: 100%;
	position: absolute;
	bottom: 28px;
	left: 0;
	padding: 0 10px;
	.icon-link {
		color: $black;
		font-weight: 500;
		font-size: 14px;
		line-height: 24px;
	}
	@include media-breakpoint-up(xl) {
		.cta-link {
			margin-right: 52px;
		}
	}
	@include media-breakpoint-only(lg) {
		bottom: 10px;
		.icon-link {
			margin-left: 15px;
			margin-right: 15px;
		}
	}
	@include media-breakpoint-down(md) {
		position: relative;
		bottom: auto;
		left: auto;
		padding: 0;
	}
	@include media-breakpoint-only(md) {
		.cta-link {
			margin-right: 44px;
		}
	}
	@include media-breakpoint-down(sm) {
		.icon-link {
			margin: 0 15px 10px;
		}
	}
}

.event-1__item--narrow {
	@include media-breakpoint-up(xl) {
		.event-1__links {
			.cta-link {
				margin-right: 36px;
			}
		}
	}
	@include media-breakpoint-only(lg) {
		.event-1__container {
			padding-top: 5px;
		}
		.event-1__date {
			margin-bottom: 6px;
		}
		.event-1__text {
			margin-bottom: 24px;
		}
		.event-1__links {
			.icon-link {
				margin-bottom: 10px;
			}
		}
	}
}
