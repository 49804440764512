/*--------------------------------------------------------------
## banners
--------------------------------------------------------------*/

.banner {
	position: relative;
	background-color: $color-primary;
	&:last-child {
		margin-bottom: 60px;
	}
	@include media-breakpoint-only(md) {
		padding-left: 52px;
		padding-right: 52px;
	}
	@include media-breakpoint-down(sm) {
		.btn {
			width: 100%;
			text-align: left;
			font-size: 13px;
			padding-top: 14px;
			padding-bottom: 14px;
			&::after {
				font-size: 14px;
				top: 14px;
				right: 18px;
			}
		}
	}
	&::after {
		display: none !important; //sass-lint:disable-line no-important
	}
}

.banner__container {
	position: relative;
	width: 100%;
	max-width: 780px;
	z-index: 10;
	.btn {
		padding: 14px 57px;
		text-align: center;
	}
	@include media-breakpoint-only(lg) {
		max-width: 768px;
		margin-bottom: 0;
		margin-top: 0;
	}
	@include media-breakpoint-down(md) {
		margin-bottom: 0;
		margin-top: 0;
	}
}

.banner__container--center {
	margin: 0 auto;
	text-align: center;
}

.banner__container--right {
	text-align: right;
	float: right;
}

.banner__pretitle {
	font-family: $font-outlined;
	color: $white;
	text-transform: uppercase;
	font-size: 30px;
	line-height: 28px;
	letter-spacing: 4.94px;
	margin-bottom: 35px;
	-webkit-text-stroke-width: 0.5px; //sass-lint:disable-line no-vendor-prefixes
	-webkit-text-stroke-color: $white; //sass-lint:disable-line no-vendor-prefixes
	@include media-breakpoint-down(sm) {
		margin-bottom: 20px;
	}
}

.banner__title {
	font-size: 150px;
	color: $white;
	font-weight: normal;
	line-height: 85px;
	margin-bottom: 14px;
	margin-left: -5px;
	letter-spacing: 9px;
	padding-left: 5px;
	font-family: $font-secondary;
	@include media-breakpoint-only(lg) {
		font-size: 60px;
		line-height: 66px;
		margin-bottom: 12px;
		margin-left: -4px;
		letter-spacing: -0.67px;
	}
	@include media-breakpoint-only(md) {
		font-size: 100px;
		line-height: 70px;
		margin-left: -4px;
		margin-bottom: 12px;
		letter-spacing: -0.44px;
	}
	@include media-breakpoint-down(sm) {
		font-size: 80px;
		line-height: 50px;
		margin-left: 0;
		margin-bottom: 5px;
		letter-spacing: -0.3px;
	}
}

.banner__text {
	color: $white;
	font-weight: 500;
	margin-bottom: 34px;
	line-height: 28px;
	@include media-breakpoint-between(md, lg) {
		font-size: 16px;
		line-height: 28px;
	}
	@include media-breakpoint-only(lg) {
		margin-bottom: 24px;
	}
	@include media-breakpoint-only(md) {
		margin-bottom: 18px;
	}
	@include media-breakpoint-down(sm) {
		font-size: 14px;
		line-height: 24px;
		margin-bottom: 20px;
	}
}

.banner--white {
	background-color: $white;
	.banner__pretitle,
	.banner__title,
	.banner__text {
		color: $color-primary;
	}
}

/*--------------------------------------------------------------
## banner-primary
--------------------------------------------------------------*/

.banner-primary {
	min-height: 100vh;
	padding-top: 32.9vh;
	padding-bottom: 240px;
	& ~ .news-feed {
		.news-feed__article-title {
			max-height: 84px;
		}
	}
	@include media-breakpoint-only(lg) {
		padding-top: 192px;
		padding-bottom: 180px;
	}
	@include media-breakpoint-only(md) {
		padding-top: 120px;
		padding-bottom: 40px;
		display: flex;
		align-items: center;
	}
	@include media-breakpoint-down(md) {
		& ~ .news-feed {
			.news-feed__article-title {
				max-height: none;
			}
		}
	}
	@include media-breakpoint-down(sm) {
		padding-top: 110px;
		padding-bottom: 40px;
		display: flex;
		align-items: center;
	}
}

.banner-primary--no-news-feed {
	padding: 120px 0 50px;
	display: flex;
	align-items: center;
	height: 100vh;
	@include media-breakpoint-down(sm) {
		height: auto;
	}
}

/*--------------------------------------------------------------
## banner-secondary
--------------------------------------------------------------*/

.banner-secondary {
	min-height: 700px;
	padding-top: 120px;
	padding-bottom: 180px;
	& ~ .news-feed {
		.news-feed__container {
			bottom: 40px;
		}
		.news-feed__article-title {
			max-height: 56px;
		}
	}
	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { //sass-lint:disable-line no-vendor-prefixes
		height: 800px;
	}
	@include media-breakpoint-only(lg) {
		padding-top: 120px;
		padding-bottom: 140px;
		min-height: 600px;
		& ~ .news-feed {
			.news-feed__container {
				bottom: 20px;
			}
		}
	}
	@include media-breakpoint-only(md) {
		padding-top: 120px;
		padding-bottom: 40px;
		min-height: 500px;
		display: flex;
		align-items: center;
		.banner__text {
			margin-bottom: 14px;
		}
	}
	@include media-breakpoint-down(md) {
		& ~ .news-feed {
			.news-feed__container {
				bottom: auto;
			}
			.news-feed__article-title {
				max-height: none;
			}
		}
	}
	@include media-breakpoint-down(sm) {
		padding-top: 110px;
		padding-bottom: 40px;
		min-height: 470px;
	}
}

.banner-secondary--no-news-feed {
	padding: 123px 0 50px;
	display: flex;
}
