/*--------------------------------------------------------------
## header
--------------------------------------------------------------*/

.header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	padding: 0 50px;
	background-color: $white;
	z-index: 100;
	.loaded & {
		transition: $duration $ease;
	}
	@include media-breakpoint-only(lg) {
		padding: 0 26px;
	}
	@include media-breakpoint-down(md) {
		padding: 0 20px;
	}
}

.header--scrolled {
	box-shadow: 0 0 15px 0 rgba($black, 0.12);
}

.header__container {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.logo {
	position: relative;
	z-index: 10;
}

.logo__image {
	.loaded & {
		transition: $duration-fast $ease;
	}
}

.logo__image--white {
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
}

.nav-toggle {
	position: absolute;
	top: 35px;
	right: 27px;
	height: 30px;
	width: 32px;
	overflow: visible;
	display: none;
	z-index: 4;
	.nav-toggle__line,
	&::before,
	&::after {
		position: absolute;
		top: 50%;
		left: 0;
		display: inline-block;
		width: 100%;
		height: 2px;
		background-color: $color-primary;
	}
	.nav-toggle__line {
		transform: translateY(-50%);
		.loaded & {
			transition: 0 $ease $duration-fast;
		}
	}
	&::before,
	&::after {
		content: '';
		transform-origin: center;
		transform: translateY(-50%);
		.loaded & {
			transition: margin $duration-fast $ease $duration-fast, transform $duration-fast $ease;
		}
	}
	&::before {
		margin-top: -8px;
	}
	&::after {
		margin-top: 8px;
	}
	@include media-breakpoint-down(lg) {
		display: block;
	}
	@include media-breakpoint-down(md) {
		right: 20px;
	}
	@include media-breakpoint-down(sm) {
		top: 20px;
		width: 28px;
	}
}

.nav-toggle__text {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 46px;
	font-weight: $bold;
	font-size: 18px;
	color: $color-primary;
	text-transform: uppercase;
	letter-spacing: -0.4px;
	@include media-breakpoint-down(sm) {
		display: none;
	}
}

.navigation-wrap {
	@include media-breakpoint-down(lg) {
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		width: 100%;
		opacity: 0;
		visibility: hidden;
		background-color: $pink;
		z-index: 20;
		.loaded & {
			transition: $duration $ease;
		}
	}
}

.navigation {
	@include media-breakpoint-down(lg) {
		position: absolute;
		top: 70px;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: $silver-light;
		.main-nav {
			position: fixed;
			left: 0;
			top: 120px;
			width: 100%;
			max-height: calc(100% - 120px);
			background-color: $white;
			border-top: 1px solid $gray-lightest;
			overflow-y: auto;
		}
		.main-nav__item,
		.main-nav__secondary-item,
		.main-nav__tertiary-item {
			position: relative;
			padding: 0;
			border-bottom: 1px solid $gray-lightest;
		}
		.main-nav__link,
		.main-nav__secondary-link,
		.main-nav__tertiary-link {
			color: $black;
			padding: 17px 25px 16px;
			letter-spacing: -0.1px;
			display: block;
		}
		.main-nav__has-children {
			&::after {
				@include font-styles;
				content: font-char(ico-chevron-down);
				position: absolute;
				top: 18px;
				right: 24px;
				font-size: 14px;
				transform: rotate(-90deg);
				pointer-events: none;
				color: $aqua;
			}
		}
		.main-nav__secondary-list {
			position: fixed;
			left: 100%;
			top: 176px;
			width: 100%;
			background-color: $white;
			max-height: calc(100% - 176px);
			overflow-y: auto;
			margin: 0;
			display: block;
			z-index: 10;
			.loaded & {
				transition: left $duration $ease;
			}
		}
		.main-nav__featured {
			display: none;
		}
		.main-nav__tertiary-list {
			position: fixed;
			left: 100%;
			top: 232px;
			width: 100%;
			background-color: $white;
			max-height: calc(100% - 120px);
			overflow-y: auto;
			margin: 0;
			z-index: 20;
			.loaded & {
				transition: left $duration $ease;
			}
		}
	}
	@include media-breakpoint-down(sm) {
		.share {
			right: 78px;
		}
		.main-nav {
			.main-nav__chevron {
				right: 0;
			}
		}
		.main-nav__link,
		.main-nav__secondary-link,
		.main-nav__tertiary-link {
			padding: 17px 20px 16px;
		}
	}
}

.navigation__back-button {
	display: none;
	@include media-breakpoint-down(lg) {
		position: absolute;
		top: 0;
		left: 25px;
		font-size: 16px;
		font-weight: 600;
		color: $color-primary;
		line-height: 52px;
		letter-spacing: -0.4px;
	}
	@include media-breakpoint-down(sm) {
		left: 20px;
	}
}

.navigation__main-link {
	display: none;
	@include media-breakpoint-down(lg) {
		display: block;
		font-size: 16px;
		font-weight: 600;
		color: $color-primary;
		line-height: 52px;
		letter-spacing: -0.4px;
		padding-left: 25px;
	}
	@include media-breakpoint-down(sm) {
		display: block;
		font-size: 16px;
		font-weight: 600;
		color: $color-primary;
		line-height: 52px;
		letter-spacing: -0.4px;
		padding-left: 20px;
	}
}

.navigation__back-icon {
	margin-left: -1px;
	margin-right: 7px;
	font-size: 12px;
	display: inline-block;
	&::before {
		transform: rotate(90deg);
	}
}

.main-nav__bootom-item {
	display: none;
	border-bottom: 1px solid $gray-lightest;
	background-color: $silver-lightest;
	@include media-breakpoint-down(lg) {
		display: block;
	}
}

.main-nav__bottom-link {
	color: $black;
	padding: 17px 25px 16px 37px;
	letter-spacing: -0.1px;
	display: block;
	position: relative;
	&::before {
		@include font-styles;
		content: font-char(ico-up-right);
		position: absolute;
		display: inline-block;
		font-size: 8px;
		left: 19px;
		top: 23px;
	}
}
