/*--------------------------------------------------------------
## signpost-2
--------------------------------------------------------------*/

.signpost-2 {
	padding: 90px 0 30px;
	.homepage & {
		overflow: hidden;
	}
	@include media-breakpoint-only(lg) {
		padding: 40px 0 0;
	}
	@include media-breakpoint-only(md) {
		padding: 30px 0 10px;
		.wrap {
			padding-left: 7.5px;
			padding-right: 7.5px;
		}
	}
	@include media-breakpoint-down(sm) {
		padding: 30px 0 20px;
	}
}

.signpost-2__item {
	position: relative;
	min-height: 445px;
	height: calc(100% - 60px);
	background-color: $color-primary;
	margin-bottom: 60px;
	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba($black, 0.2);
	}
	@include media-breakpoint-only(lg) {
		min-height: 360px;
	}
	@include media-breakpoint-only(md) {
		min-height: 352px;
		height: calc(100% - 40px);
		background-color: $color-primary;
		margin-bottom: 40px;
		.mixed-modules & {
			margin-left: 62.5px;
			margin-right: 62.5px;
		}
	}
	@include media-breakpoint-down(sm) {
		height: auto;
		min-height: 353px;
		margin-bottom: 20px;
	}
}

.signpost-2__content {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 42px 15px 42px 30px;
	z-index: 10;
	&::after {
		content: '';
		display: block;
		background: $gradient-line;
		position: absolute;
		height: 5px;
		left: 0;
		right: 0;
		bottom: 0;
	}
	@include media-breakpoint-only(lg) {
		padding: 26px 16px;
	}
	@include media-breakpoint-only(md) {
		padding: 28px 30px;
	}
	@include media-breakpoint-down(sm) {
		padding: 22px 16px;
	}
}

.signpost-2__title {
	font-size: 32px;
	color: $white;
	line-height: 30px;
	letter-spacing: 0.45px;
	margin-bottom: 20px;
	font-weight: $regular;
	font-family: $font-bebas;
	@include media-breakpoint-only(lg) {
		margin-bottom: 4px;
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 4px;
	}
}

.signpost-2__text {
	font-size: 18px;
	color: $white;
	line-height: 28px;
	margin-bottom: 30px;
	@include media-breakpoint-only(md) {
		max-width: 480px;
		margin-bottom: 22px;
	}
}

.signpost-2__cta {
	color: $white;
	margin-bottom: 0;
	&::before {
		color: $white;
	}
	@include hover {
		&::before {
			color: $white;
		}
	}
}

.signpost-2__item--narrow {
	@include media-breakpoint-up(xl) {
		min-height: 330px;
		.signpost-2__content {
			padding: 30px 26px;
		}
		.signpost-2__text {
			margin-bottom: 18px;
		}
	}
	@include media-breakpoint-only(lg) {
		min-height: 312px;
		.signpost-2__content {
			padding: 22px 10px 22px 15px;
		}
		.signpost-2__text {
			margin-bottom: 15px;
		}
	}
}
