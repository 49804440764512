/*--------------------------------------------------------------
## news-feed
--------------------------------------------------------------*/

.news-feed {
	position: relative;
	height: 0;
	.wrap {
		max-width: 1370px;
	}
	@include media-breakpoint-down(md) {
		height: auto;
		margin-bottom: 10px;
		padding-top: 18px;
		.news-feed__article {
			&:first-of-type::before {
				display: none;
			}
		}
	}
	@include media-breakpoint-down(sm) {
		padding-top: 22px;
		margin-bottom: 24px;
	}
}

.news-feed__slider {
	.slick-dots {
		display: flex;
		li {
			width: 10px;
			height: 10px;
			border: 1px solid $color-primary;
			border-radius: 50%;
			font-size: 0;
			&:not(:last-child) {
				margin-right: 10px;
			}
			&.slick-active {
				background-color: $color-primary;
			}
		}
	}
	@include media-breakpoint-down(sm) {
		margin: 0;
	}
}

.news-feed--center {
	text-align: center;
	.news-feed__article {
		padding-left: 25px;
		padding-right: 25px;
		&:first-of-type::before {
			display: none;
		}
	}
	.slick-dots {
		justify-content: center;
	}
	@include media-breakpoint-down(lg) {
		.news-feed__article {
			padding-left: 15px;
			padding-right: 15px;
		}
	}
	@include media-breakpoint-down(sm) {
		.news-feed__article {
			padding-left: 0;
			padding-right: 0;
		}
	}
}

.news-feed--right {
	text-align: right;
	.news-feed__article {
		padding-left: 42px;
		padding-right: 21px;
		&::before {
			left: auto;
			right: 0;
		}
	}
	.news-feed__article-tag {
		margin-right: 0;
	}
	.news-feed__article-date {
		margin-left: 12px;
	}
	.slick-dots {
		justify-content: flex-end;
	}
	@include media-breakpoint-only(md) {
		.news-feed__article {
			padding-left: 15px;
			padding-right: 15px;
			&::before {
				right: -5px;
			}
			&:first-of-type::before {
				display: block;
			}
			&:last-of-type::before {
				display: none;
			}
		}
	}
	@include media-breakpoint-down(sm) {
		.news-feed__article {
			padding-left: 0;
			padding-right: 0;
		}
		.news-feed__article-date {
			margin-left: 24px;
		}
	}
}

.news-feed__container {
	position: absolute;
	bottom: 68px;
	left: 0;
	width: 100%;
	@include media-breakpoint-only(lg) {
		bottom: 40px;
		padding: 0 15px;
	}
	@include media-breakpoint-down(md) {
		position: relative;
		bottom: auto;
		left: auto;
	}
}

.news-feed__article {
	position: relative;
	padding: 10px 42px 6px 21px;
	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 1px;
		height: 100%;
		background-color: $white;
	}
	@include media-breakpoint-down(lg) {
		padding: 8px 15px 4px;
	}
	@include media-breakpoint-down(md) {
		padding-top: 0;
		padding-bottom: 0;
		&::before {
			background-color: $color-primary;
		}
	}
	@include media-breakpoint-down(sm) {
		padding-left: 0;
		padding-right: 0;
		padding-bottom: 12px;
		&::before {
			display: none;
		}
	}
}

.news-feed__article-meta {
	margin-bottom: 12px;
	padding-left: 4px;
	@include media-breakpoint-down(md) {
		padding-left: 0;
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 10px;
	}
}

.news-feed__article-tag {
	font-weight: $bold;
	color: $white;
	text-transform: uppercase;
	margin-right: 12px;
	display: inline-block;
	@include hover {
		text-decoration: underline;
	}
	@include media-breakpoint-down(md) {
		color: $color-primary;
	}
	@include media-breakpoint-down(sm) {
		margin-right: 24px;
	}
}

.news-feed__article-date {
	color: $white;
	display: inline-block;
	@include media-breakpoint-down(md) {
		color: $color-primary;
	}
}

.news-feed__article-title {
	color: $white;
	margin-bottom: 0;
	overflow: hidden;
	@include media-breakpoint-down(lg) {
		font-size: 14px;
		line-height: 24px;
	}
	@include media-breakpoint-down(md) {
		color: $color-primary;
		font-weight: $light;
	}
	@include media-breakpoint-down(sm) {
		font-weight: $bold;
	}
}

.news-feed--dark {
	.news-feed__article {
		&::before {
			background-color: $color-primary;
		}
	}
	.news-feed__article-tag,
	.news-feed__article-date,
	.news-feed__article-title {
		color: $color-primary;
	}
}
