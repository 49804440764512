/*--------------------------------------------------------------
## forms
--------------------------------------------------------------*/
input,
textarea,
select {
	font-family: $font-default;
	background: $white;
	border-radius: 0;
	box-shadow: none;
	color: $color-primary;
	&:-webkit-autofill { // sass-lint:disable-line no-vendor-prefixes
		box-shadow: inset 0 0 0 1000px $white;
	}
	&::placeholder {
		color: $color-secondary;
	}
	&::-ms-clear { // sass-lint:disable-line no-vendor-prefixes
		display: none;
	}
}

select {
	font-family: $font-default;
	padding-left: 10px;
	padding-right: 40px;
	font-size: 14px;
	font-weight: $light;
	color: $color-primary;
	line-height: 38px;
	height: 38px;
	appearance: none;
	background: url('../assets/images/select-icon.svg') right 12px center no-repeat;
	background-size: 10px 10px;
	@include media-breakpoint-down(sm) {
		max-width: 100%;
		width: 100%;
	}
}

.form__row,
.umbraco-forms-field {
	position: relative;
	margin-bottom: 32px;
	@include media-breakpoint-between(md, lg) {
		margin-bottom: 28px;
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 20px;
	}
}

.form__label,
.umbraco-forms-label {
	font-size: 16px;
	font-weight: normal;
	color: $color-primary;
	line-height: 24px;
	margin-bottom: 6px;
	display: block;
}

.form__input {
	width: 100%;
	height: 40px;
	font-size: 14px;
	font-weight: $light;
	padding: 0 10px;
	background-color: #fdfdfd;
	border: 1px solid $gray-lightest;
	.loaded & {
		transition: $duration $ease;
	}
	@include hover {
		border-color: $pink;
	}
	&:active,
	&:focus {
		border-color: $pink;
	}
	&:invalid {
		background-color: transparent;
	}
	@include media-breakpoint-down(sm) {
		font-size: 16px;
	}
}

.form__select {
	@include media-breakpoint-down(sm) {
		font-size: 16px;
	}
}

.form__input--error {
	border-color: #edb5c0;
	background-image: url('../assets/images/error-mark.svg');
	background-position: right 12px center;
	background-repeat: no-repeat;
	background-size: 12px 12px;
}

.form__textarea {
	height: 160px;
	padding: 10px;
	resize: none;
	width: 100%;
	background-color: #fdfdfd;
	border: 1px solid $gray-lightest;
	.loaded & {
		transition: $duration $ease;
	}
	@include hover {
		border-color: $pink;
	}
	&:active,
	&:focus {
		border-color: $pink;
	}
	&:invalid {
		background-color: transparent;
	}
}

.form__group {
	padding-top: 10px;
	.form__label {
		margin-bottom: 18px;
	}
}

.form__checkbox-row,
.form__radio-row {
	position: relative;
	input {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		height: 20px;
		width: 20px;
		z-index: 2;
		cursor: pointer;
	}
}

.form__checkbox-row {
	margin-bottom: 18px;
	input:checked ~ .form__label-checkbox::after {
		opacity: 1;
		transform: rotate(45deg);
	}
}

.form__label-checkbox {
	position: relative;
	font-size: 16px;
	color: $silver-darkest;
	line-height: 24px;
	padding: 3px 0 3px 45px;
	display: inline-block;
	font-weight: normal;
	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 30px;
		height: 30px;
		background-color: $white;
		border: 1px solid $gray-lightest;
		.loaded & {
			transition: $duration $ease;
		}
	}
	&::after {
		content: '';
		position: absolute;
		left: 11px;
		top: 6px;
		width: 8px;
		height: 14px;
		border: solid $pink;
		border-width: 0 2px 2px 0;
		opacity: 0;
		transform: rotate(115deg);
		display: block;
		.loaded & {
			transition: $duration $ease;
		}
	}
	@include hover {
		&::before {
			border-color: $pink;
		}
	}
}

.form__radio-row {
	margin-bottom: 18px;
	input:checked ~ .form__label-radio::after {
		opacity: 1;
	}
}

.form__label-radio {
	position: relative;
	font-size: 16px;
	color: $gray;
	line-height: 24px;
	padding: 3px 0 3px 40px;
	display: inline-block;
	&::before {
		content: '';
		position: absolute;
		top: 2px;
		left: 0;
		height: 26px;
		width: 26px;
		background-color: $pink;
		border: 1px solid $gray-lightest;
		border-radius: 50%;
		.loaded & {
			transition: $duration $ease;
		}
	}
	&::after {
		content: '';
		position: absolute;
		top: 7px;
		left: 5px;
		width: 16px;
		height: 16px;
		border-radius: 50%;
		background: $gray-lightest;
		opacity: 0;
		display: block;
		.loaded & {
			transition: $duration $ease;
		}
	}
	@include hover {
		&::before {
			border-color: $color-primary;
		}
		&::after {
			background-color: $color-primary;
		}
	}
}

.field-validation-error {
	padding-top: 4px;
	color: #d0021b;
	display: block;
	font-size: 12px;
}

.input-validation-error {
	border-color: #edb5c0;
	// sass-lint:disable no-important
	background-image: url('../assets/images/error-mark.svg') !important;
	background-position: right 12px center !important;
	background-repeat: no-repeat !important;
	background-size: 12px 12px !important;
	// sass-lint:enable no-important
}

// Umbraco forms specific styles
.umbraco-forms-page {
	.row-fluid {
		margin: 0 -15px;
	}
	.checkboxlist,
	.radiobuttonlist {
		padding-top: 12px;
	}
	.field-validation-error {
		padding-top: 4px;
		color: #bf060e;
		display: block;
		font-size: 12px;
	}
	.input-validation-error {
		border-color: #edb5c0;
		background-image: url('../assets/images/error-mark.svg') !important; // sass-lint:disable-line no-important
		background-position: right 12px center !important; // sass-lint:disable-line no-important
		background-repeat: no-repeat !important; // sass-lint:disable-line no-important
		background-size: 12px 12px !important; // sass-lint:disable-line no-important
	}
}

.umbraco-forms-navigation {
	.btn {
		@extend .btn;
		height: 50px;
		min-width: 220px;
	}
}

.umbraco-forms-submitmessage {
	font-size: 28px;
	font-weight: 500;
	padding-top: 20px;
	margin-bottom: 60px;
	@include media-breakpoint-down(sm) {
		font-size: 22px;
	}
}
