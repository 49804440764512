/*--------------------------------------------------------------
## search-results
--------------------------------------------------------------*/

.search-results {
	.pagination {
		padding-top: 62px;
		margin-right: 40px;
		margin-bottom: 0;
	}
	@include media-breakpoint-only(md) {
		padding: 0 50px;
		.pagination {
			padding-top: 40px;
		}
	}
	@include media-breakpoint-down(sm) {
		.pagination {
			padding-top: 22px;
			margin-right: 0;
		}
	}
}

.search-results__search {
	max-width: 250px;
	position: relative;
	@include media-breakpoint-only(lg) {
		padding-top: 10px;
	}
	@include media-breakpoint-only(md) {
		max-width: 100%;
		margin-bottom: 38px;
	}
	@include media-breakpoint-down(sm) {
		max-width: 100%;
		margin-bottom: 26px;
	}
}

.search-results__search-title {
	font-weight: $bold;
	color: $color-primary;
	font-size: 20px;
	margin-bottom: 15px;
	display: block;
}

.search-results__search-btn {
	position: absolute;
	right: 5px;
	bottom: 0;
	padding: 5px;
	font-size: 18px;
	color: $color-primary;
	.icon {
		.loaded & {
			transition: $duration $ease;
		}
	}
	@include hover {
		.icon {
			color: $color-hover;
		}
	}
}

.search-results__wrapper {
	margin-bottom: 60px;
	@include media-breakpoint-down(sm) {
		margin-bottom: 38px;
	}
}

.search-results__title {
	padding-top: 3px;
	margin-bottom: 18px;
	font-size: 24px;
	font-weight: $light;
	@include media-breakpoint-only(md) {
		margin-bottom: 28px;
	}
	@include media-breakpoint-down(sm) {
		font-size: 20px;
		line-height: 28px;
		margin-bottom: 20px;
	}
}

.search-results__item {
	padding: 20px 10px 38px 20px;
	border-top: 1px solid $silver-light;
	@include media-breakpoint-only(lg) {
		padding: 20px 20px 62px;
	}
	@include media-breakpoint-only(md) {
		padding: 20px 18px 62px;
	}
	@include media-breakpoint-down(sm) {
		padding: 10px 0 26px;
	}
}

.search-results__item-title {
	margin-bottom: 10px;
	@include media-breakpoint-only(lg) {
		margin-bottom: 2px;
	}
	@include media-breakpoint-only(md) {
		margin-left: 3px;
		margin-bottom: 2px;
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 5px;
	}
}

.search-results__item-url {
	font-size: 14px;
	font-weight: $regular;
	color: $gray-light;
	margin-bottom: 10px;
	display: block;
}

.search-results__item-text {
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.42px;
	font-weight: $regular;
	color: $color-primary;
	@include media-breakpoint-down(sm) {
		line-height: 1.9;
	}
}
